import {Box, Button, Typography, useTheme} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import Header from "../../Components/Header";
import {LockOutlined} from "@mui/icons-material";
import {useState} from "react";
import {delete_ip, get_ips, server_check, server_restart, server_ss} from "../../appconfig";
import {toast} from "react-toastify";
import {handleLogout} from "../../constant";
// import {useNavigate} from "react-router-dom";
// import {useEffect} from "react";

let mockDataTeam = [];

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const Team = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(() => {
        return false
    });

    const columns = [
        // { field: "id", headerName: "ID" },
        // {
        //     field: "name",
        //     headerName: "Name",
        //     flex: 1,
        //     cellClassName: "name-column--cell",
        // },
        {
            field: "name",
            headerName: "Name",
            flex: 0.3,
            minWidth: 120,
        },
        {
            field: "ipaddr",
            headerName: "IP Address",
            flex: 0.5,
            minWidth: 80,
        },
        {
            field: "cpu",
            headerName: "CPU (%)",
            flex: 0.3,
        },
        {
            field: "ram",
            headerName: "RAM (%)",
            flex: 0.3,
        },
        {
            field: "platform",
            headerName: "Platform",
            flex: 0.4,
        },
        {
            field: "logs",
            headerName: "Logs",
            flex: 0.4,
        },
        {
            field: "system",
            headerName: "System Info",
            flex: 0.9,

        },
        {
            field: "Activity",
            headerName: "Activity",
            flex: 0.5,
            renderCell: ({ row: { access } }) => {
                return (

                    <Box
                        width="40%"
                        m="0 auto"
                        p="4px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            access === "on"
                                ? colors.greenAccent[600]
                                : access === "off"
                                    ? colors.grey[700]
                                    : colors.grey[700]
                        }
                        borderRadius="4px"
                    >
                        {access === "off" && <LockOutlined />}
                        {access === "on" && <LockOpenOutlinedIcon />}
                        <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                            {access}
                        </Typography>
                    </Box>
                );
            },
        },
    ];

    const [select, setSelection] = useState(() => {
        return [{}]
    });

    const [temp, tempset] = useState(() => {
        return [];
    });

    let email_ = sessionStorage.getItem('Email')
    let email_x;

    if (email_) {
        email_x = email_;
    }

    if (!email_) {
        handleLogout()
        return;
    }
    // server_ss('127.0.0.1').then(r => r)

    const Ipsd = async () =>{
        mockDataTeam = [];

        let server = await get_ips(email_x)
        // server.push({
        //     value: '192.168.117.2',
        //     label: '192.168.117.2',
        // })
        console.log(server)
        for (let item of server){
            if (item.value === "NONE") continue;
            let tempe = await server_check(item.value)

            if (!tempe){
                mockDataTeam.push({
                    name: 'none',
                    ipaddr: item.value.toString(),
                    cpu: 'none',
                    ram: 'none',
                    platform: 'none',
                    logs: 'none',
                    access: 'off',
                    system: 'none'
                })
            }
            else{
                try {
                    mockDataTeam.push({
                        name: tempe.data.name.toString(),
                        ipaddr: item.value.toString(),
                        cpu: tempe.data.cpu.toString()+'%',
                        ram: tempe.data.ram.toString()+'%',
                        platform: tempe.data.platform.toString(),
                        logs: tempe.data.logs.toString(),
                        system: 'CPU: '+tempe.data.system.toString()+' RAM: '+tempe.data.ram_info.toString(),
                        access: 'on',
                    })
                } catch (e) {
                    console.log(e)
                }
            }
        }

        await tempset(mockDataTeam)
    }

    const handleFormSubmit = async (fg) => {
        try {
            setLoading(true);
            for (const data of select) {
                for (let li of mockDataTeam) {
                    if (li.ipaddr === data) {
                        await server_restart(li.ipaddr, fg).then()
                    }

                }
                await sleep(1000)
                // mockDataTeam.forEach((li) => {
                //     if (li.ipaddr === data){
                //         server_restart(li.ipaddr).then()
                //     }
                // })
            }
            toast.success(`WAIT FOR 10 SEC`)
            await sleep(10000)
            toast.success(`RESTARTED LIST`)
            await Ipsd()
        } catch (e) {
            console.log(e)
        }
    };


    const handleFormSubmitre = async () => {
        try {
            for (const data of select) {
                for (let li of mockDataTeam) {
                    if (li.ipaddr === data) {
                        await delete_ip(email_x, li.ipaddr).then()

                    }
                }

            }
            toast.success(`REMOVED LIST`)
            await Ipsd()

        } catch (e) {
            console.log(e)
        }
    };


    return (
        <Box m="20px">
            <Header title="SERVERS" subtitle="ACTIVE SERVER | CLICK REFRESH TO EXTRACT ALL OF YOURS SERVERS" />

            <Box
                m="30px 0 0 0"
                height="60vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}>
                <DataGrid pageSize={25}
                          checkboxSelection
                          getRowHeight={() => 'auto'}
                          rowThreshold={0}
                          hideFooterPagination rows={temp} columns={columns}
                          getRowId={(row) =>  row.ipaddr}
                          onSelectionModelChange={(newSelection) => {
                              setSelection(newSelection);
                          }}/>

                <Box
                    justifyContent="end" mt="40px" display="grid"
                     gap="30px"
                     gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                     sx={{
                         "& > div": { gridColumn: "span 4" },
                     }}
                >
                    <Button disabled={loading} onClick={async () => handleFormSubmit(true)}
                            type="submit" color="secondary" variant="contained">
                        Restart Selected.
                    </Button>
                    <Button disabled={loading} onClick={async () => handleFormSubmit(false)}
                            type="submit" color="warning" variant="contained">
                        Restart Bots in.
                    </Button>
                    <Button onClick={async () => handleFormSubmitre()} type="submit" color="error" variant="contained">
                        Remove Selected.
                    </Button>

                    <Button onClick={async () => Ipsd()} type="submit" color="info" variant="contained">
                        Refresh Chart.
                    </Button>
                    <Typography variant="h3" color={colors.redAccent[500]} sx={{gridColumn: "span 3"}}>
                        RESTART SERVER OR RUNNING BOTS IN SERVER | REMOVE IP FROM LIST DB | REFRESH LISTS |
                        BOT WILL ONLY RESTART IF THEY WHERE RUNNING IN FIRST PLACE
                    </Typography>
                    <Typography variant="h4" color={colors.redAccent[500]} sx={{gridColumn: "span 4"}}>
                        AFTER CLICKING WAIT FOR PAGE TO REFRESH DON'T SPAM WITH CLICKS
                        {`\n`} WAIT IT LL TAKE TIME TO EXTRACT!!
                    </Typography>

                </Box>

            </Box>

        </Box>
    );
};

export default Team;

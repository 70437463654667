import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
// import { mockTransactions } from "../../data/mockData";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import PublicIcon from '@mui/icons-material/Public';
import ComputerIcon from '@mui/icons-material/Computer';
import Header from "../../Components/Header";
import GeographyChart from "../../Components/GeographyChart";
import StatBox from "../../Components/StatBox";
import ProgressCircle from "../../Components/ProgressCircle";
import {get_ips, total_streams} from "../../appconfig";
import {useState} from "react";



const Dashboard = () => {
  const theme = useTheme();
  let [sc, setsc] = useState(() => {
      return '0';
  });
    let [totals, totalset] = useState(() => {
        return '0';
    });
  const colors = tokens(theme.palette.mode);
  let email_ = sessionStorage.getItem('Email')
  let email_x;
  if (email_) {
    email_x = email_;
  }
  if (!email_) {
    email_x = 'USER';
  }

  const server_count = async () => {
      try{
          let r = await get_ips(email_x);
          let rr = await total_streams(email_x)
          if(rr[0]) totalset(rr[1]);

          setsc(`${r.length - 1}`.toString())

      }
      catch (e) {}
  }

return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={email_x.split("@")[0]} subtitle="Welcome to your dashboard" />

        <Box>
          {/*<Button*/}
          {/*  sx={{*/}
          {/*    backgroundColor: colors.blueAccent[700],*/}
          {/*    color: colors.grey[100],*/}
          {/*    fontSize: "14px",*/}
          {/*    fontWeight: "bold",*/}
          {/*    padding: "10px 20px",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <DownloadOutlinedIcon sx={{ mr: "10px" }} />*/}
          {/*  Download Reports*/}
          {/*</Button>*/}
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totals}
            subtitle="Total Streams Sent"
            progress="0.75"
            // increase="+14%"
            icon={
              <RocketLaunchIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Total Accounts"
            progress="0.80"
            // increase="+21%"
            icon={
              <RecentActorsIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="0"
            subtitle="Proxy Total"
            progress="0.80"
            // increase="+5%"
            icon={
              <PublicIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={sc}
            subtitle="Servers"
            progress="0.80"
            // increase="+43%"
            icon={
              <ComputerIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 spotify */}
        <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <StatBox
              title="0"
              subtitle="Spotify Streams"
              progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />
          <StatBox
              title="0"
              subtitle="Napster Streams"
              progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />

        </Box>
        {/* ROW 2 iheart deez */}
        <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <StatBox
              title="0"
              subtitle="Iheart Streams"
              progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />
          <StatBox
              title="0"
              subtitle="Deezer Streams"
              progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />

        </Box>
        {/* ROW 2 amz ytmusci */}
        <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <StatBox
              title="0"
              subtitle="Amazon Streams"
              progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />
          <StatBox
              title="0"
              subtitle="YTmusic Streams"
              progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />

        </Box>
        {/* ROW 2 Soundc yt */}
        <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <StatBox
              title="0"
              subtitle="SoundCloud Streams"
              progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />
          <StatBox
              title="0"
              subtitle="Youtube Streams"
              progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />

        </Box>
        {/* ROW 2 qobuz resso */}
        {/*<Box*/}
        {/*    gridColumn="span 3"*/}
        {/*    backgroundColor={colors.primary[400]}*/}
        {/*    display="flex"*/}
        {/*    alignItems="center"*/}
        {/*    justifyContent="center"*/}
        {/*>*/}
        {/*  <StatBox*/}
        {/*      title="0"*/}
        {/*      subtitle="Qobuz Streams"*/}
        {/*      progress="0.75"*/}
        {/*      // increase="+14%"*/}
        {/*      icon={*/}
        {/*        <RocketLaunchIcon*/}
        {/*            sx={{ color: colors.greenAccent[600], fontSize: "26px" }}*/}
        {/*        />*/}
        {/*      }*/}
        {/*  />*/}
        {/*  <StatBox*/}
        {/*      title="0"*/}
        {/*      subtitle="resso Streams"*/}
        {/*      progress="0.75"*/}
        {/*      // increase="+14%"*/}
        {/*      icon={*/}
        {/*        <RocketLaunchIcon*/}
        {/*            sx={{ color: colors.greenAccent[600], fontSize: "26px" }}*/}
        {/*        />*/}
        {/*      }*/}
        {/*  />*/}

        {/*</Box>*/}
        {/* ROW 2 Tidal tiktok */}
        <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <StatBox
              title="0"
              subtitle="Tidal Streams"
              progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />
          <StatBox
              title="0"
              subtitle="TikTok Streams"
              progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />

        </Box>
        {/* ROW 2 Apple Spreaker */}
        <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <StatBox
              title="0"
              subtitle="Apple Streams"
              // progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />
          <StatBox
              title="0"
              subtitle="Spreaker Streams"
              // progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />

        </Box>

        {/* ROW 2 FB Insta */}
        <Box
            gridColumn="span 3"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <StatBox
              title="0"
              subtitle="FB Streams"
              // progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />
          <StatBox
              title="0"
              subtitle="Insta Streams"
              // progress="0.75"
              // increase="+14%"
              icon={
                <RocketLaunchIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
          />

        </Box>

        {/*<Box*/}
        {/*  gridColumn="span 4"*/}
        {/*  gridRow="span 2"*/}
        {/*  backgroundColor={colors.primary[400]}*/}
        {/*  overflow="auto"*/}
        {/*>*/}
        {/*  <Box*/}
        {/*    display="flex"*/}
        {/*    justifyContent="space-between"*/}
        {/*    alignItems="center"*/}
        {/*    borderBottom={`4px solid ${colors.primary[500]}`}*/}
        {/*    colors={colors.grey[100]}*/}
        {/*    p="15px"*/}
        {/*  >*/}
        {/*    <Typography color={colors.grey[100]} variant="h5" fontWeight="600">*/}
        {/*      LIVE TASKs*/}
        {/*    </Typography>*/}
        {/*  </Box>*/}
        {/*  /!*live transcation mapping*!/*/}
        {/*  {mockTransactions.map((transaction,i) => (*/}
        {/*    <Box*/}
        {/*      key={`${transaction.txId}-${i}`}*/}
        {/*      display="flex"*/}
        {/*      justifyContent="space-between"*/}
        {/*      alignItems="center"*/}
        {/*      borderBottom={`4px solid ${colors.primary[500]}`}*/}
        {/*      p="15px"*/}
        {/*    >*/}
        {/*      <Box>*/}
        {/*        <Typography*/}
        {/*          color={colors.greenAccent[500]}*/}
        {/*          variant="h5"*/}
        {/*          fontWeight="600"*/}
        {/*        >*/}
        {/*          /!*{transaction.txId}*!/*/}
        {/*        </Typography>*/}
        {/*        <Typography color={colors.grey[100]}>*/}
        {/*          {transaction.user}*/}
        {/*        </Typography>*/}
        {/*      </Box>*/}
        {/*      <Box color={colors.grey[100]}>{transaction.date}</Box>*/}
        {/*      <Box*/}
        {/*        backgroundColor={colors.greenAccent[500]}*/}
        {/*        p="5px 10px"*/}
        {/*        borderRadius="4px"*/}
        {/*      >*/}
        {/*        ${transaction.cost}*/}
        {/*      </Box>*/}
        {/*    </Box>*/}
        {/*  ))}*/}
        {/*</Box>*/}

        {/* ROW 3 */}
      {/*  <Box*/}
      {/*    gridColumn="span 4"*/}
      {/*    gridRow="span 2"*/}
      {/*    backgroundColor={colors.primary[400]}*/}
      {/*    p="30px"*/}
      {/*>*/}
      {/*    <Typography variant="h5" fontWeight="600">*/}
      {/*        Campaign*/}
      {/*    </Typography>*/}
      {/*    <Box*/}
      {/*        display="flex"*/}
      {/*        flexDirection="column"*/}
      {/*        alignItems="center"*/}
      {/*        mt="25px"*/}
      {/*    >*/}
      {/*        <ProgressCircle size="125" />*/}
      {/*        <Typography*/}
      {/*            variant="h5"*/}
      {/*            color={colors.greenAccent[500]}*/}
      {/*            sx={{ mt: "15px" }}*/}
      {/*        >*/}
      {/*            $48,352 revenue generated*/}
      {/*        </Typography>*/}
      {/*        /!*<Typography>Includes extra misc expenditures and costs</Typography>*!/*/}
      {/*    </Box>*/}
      {/*</Box>*/}
        {/*<Box*/}
        {/*  gridColumn="span 4"*/}
        {/*  gridRow="span 2"*/}
        {/*  backgroundColor={colors.primary[400]}*/}
        {/*>*/}
        {/*  <Typography*/}
        {/*    variant="h5"*/}
        {/*    fontWeight="600"*/}
        {/*    sx={{ padding: "30px 30px 0 30px" }}*/}
        {/*  >*/}
        {/*    Sales Quantity*/}
        {/*  </Typography>*/}
        {/*  <Box height="250px" mt="-20px">*/}
        {/*    <BarChart isDashboard={true} />*/}
        {/*  </Box>*/}
        {/*</Box>*/}
        {/*<Box*/}
        {/*  gridColumn="span 4"*/}
        {/*  gridRow="span 2"*/}
        {/*  backgroundColor={colors.primary[400]}*/}
        {/*  padding="30px"*/}
        {/*>*/}
        {/*  <Typography*/}
        {/*    variant="h5"*/}
        {/*    fontWeight="600"*/}
        {/*    sx={{ marginBottom: "15px" }}*/}
        {/*  >*/}
        {/*    Geography Based Traffic*/}
        {/*  </Typography>*/}
        {/*  <Box height="200px">*/}
        {/*    <GeographyChart isDashboard={true} />*/}
        {/*  </Box>*/}

        {/*</Box>*/}
      </Box>
        <Button onClick={async () => server_count()} type="submit" color="info" variant="contained">
            Refresh Chart.
        </Button>
    </Box>
  );
};

export default Dashboard;

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from './Button';
// import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AcUnitSharpIcon from '@mui/icons-material/AcUnitSharp';
import Typography from '@mui/material/Typography';
import {useTheme} from "@mui/material";
import {tokens} from "../../theme";
import {navigate} from "../../App";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://waterbotter.xyz/">
                waterbotter.xyz
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function BasicTextFields({ setPassword, setEmail, handleAction }) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const handleSubmit = (event) => {
        event.preventDefault();
        handleAction()
    };
    let authToken = sessionStorage.getItem('Auth Token')

    if (authToken) {
        navigate('/')
    }


    return (
        <Grid container component="main" sx={{ height: '100vh', color: colors.greenAccent[600], fontSize: "35px" }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={6}
                md={7}
                sx={{
                    backgroundImage: 'url(https://w0.peakpx.com/wallpaper/166/499/HD-wallpaper-wlop-anime-girls-violin-brunette.jpg)',
                    backgroundRepeat: 'no-repeat',
                    color: colors.greenAccent[600],
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        '& > :not(style)': { m: 1, width: '25ch' },
                        color: colors.greenAccent[600],
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <AcUnitSharpIcon />
                    <Typography component="h1" variant="h3">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="outlined"
                            id="email"
                            label="Email Address"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="outlined"
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="current-password"
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        />
                        <Button
                        >
                            Sign In
                        </Button>
                        <Copyright sx={{ mt: 2, color: colors.greenAccent[600] }} />
                    </Box>
                </Box>
            </Grid>
        </Grid>

        // <div>
        //     <div className="heading-container">
        //         <h3>
        //             {title}
        //         </h3>
        //     </div>
        //     <Box m="20px">
        //         <Box
        //             component="form"
        //             m="30px 20 20 30"
        //             height="40vh"
        //             justifyContent="end" mt="40px" display="grid"
        //             gap="30px"
        //             gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        //
        //             sx={{
        //                 display: 'flex', justifyContent: 'center',
        //                 "& > div": { gridColumn: "span 4" },
        //                 '& > :not(style)': { m: 1, width: '25ch' },
        //                 width: 300,
        //                 height: 300,
        //                 ...commonStyles, borderRadius: '16px',
        //                 backgroundColor: 'primary.black',
        //                 '&:hover': {
        //                     backgroundColor: 'primary.main',
        //                     opacity: [9, 8, 7],},
        //
        //             }}
        //             noValidate
        //             autoComplete="on"
        //         >
        //             <TextField
        //                 id="email"
        //                 label="Enter the Email"
        //                 variant="outlined"
        //                 onChange={(e) => setEmail(e.target.value)}
        //             />
        //             <TextField
        //                 id="password"
        //                 label="Enter the Password"
        //                 variant="outlined"
        //                 onChange={(e) => setPassword(e.target.value)}
        //             />
        //         </Box>
        //     </Box>
        //
        //
        //     <Button title={title} handleAction={handleAction} />
        // </div>
    )
}

import React, {useState, useEffect} from 'react';
import './App.css';
import Form from './Components/Common/Form';
import {
    Routes,
    Route,
    useNavigate
} from "react-router-dom";
import { getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CssBaseline, ThemeProvider} from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import {ColorModeContext, useMode} from "./theme";
import Team from "./scenes/team";
import { initializeApp } from "firebase/app";
import FAQ from "./scenes/faq";
import Calendar from "./scenes/calendar/calendar";
import Geography from "./scenes/geography";
import Forms from "./scenes/forms";
import { getStorage } from "firebase/storage";
import Invoices from "./scenes/invoices";
import Line from "./scenes/line";
import Smanager from "./scenes/servermanager"
import {getFirestore} from "firebase/firestore"
import { getDatabase } from 'firebase/database'
import {accountx, make_bucket_aio, verify} from "./appconfig";
import Stats from "./scenes/stats";
import Statss from "./scenes/statschart";
import Multiserver from "./scenes/multiservermanage";
import Statsss from "./scenes/statschartlist";
import Configedit from "./scenes/configedifor";
import Contacts from "./scenes/contacts";
import Cookies from "./scenes/Cookies";
//

const firebaseConfig = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DBURL,
    projectId: process.env.REACT_APP_PROID,
    storageBucket: process.env.REACT_APP_SBUCKET,
    messagingSenderId: process.env.REACT_APP_MSID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MID
};

export let app = initializeApp(firebaseConfig);
export let storage = getStorage(app);
export let firestore = getFirestore(app)
export let fdatabase = getDatabase(app);
export let navigate = "";
let authentication = "";

export const signOut = async() => {
    try {
        await signOut(authentication)
        return true
    } catch (error) {
        return false
    }
};

function App() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    authentication = getAuth(app);


    navigate = useNavigate();

    const loginUser = async () => {
        try{
            await accountx.createEmailSession(email, password)
        }catch (e) {
            toast.error(`${e.message}`)
            return false;
        }
        await fetchUser()
        return true;
    };

    const fetchUser = async () => {
        let resp = await accountx.get()
        if (!resp.emailVerification){
            let buck = await make_bucket_aio(email)
            if (!buck[0]){
                toast.error(`${buck[1]} | FAIL_TO_MAKE_BUCKET`)
            }else{
                let red = await verify(email)
                if(!red[0]){
                    toast.error(`${red[1]} | FAIL_TO_VERIFY`)

                }}
            toast.success(` !!!! First Login !!!! `)
        }

        sessionStorage.setItem('userid', resp.$id);
    };

    const handleAction = async () => {

        const reslt = await loginUser();
        if (!reslt){
            return false;
        }
        signInWithEmailAndPassword(authentication, email, password)
            .then((response) => {
          navigate('/');
          sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken);
          sessionStorage.setItem('Email', email);
          toast.success("LoGin Success");

        }).catch((error) => {
          console.log(error.code)
          if (error.code === 'auth/wrong-password') {
            toast.error('Please check the Password');
          }
          if (error.code === 'auth/user-not-found') {
            toast.error('Please check the Email');
          }
        })
    }

    // const signOut = async() => {
    //     try {
    //         await signOut(authentication)
    //         return true
    //     } catch (error) {
    //         return false
    //     }
    // };

    useEffect(() => {
        let authToken = sessionStorage.getItem('Auth Token')

        if (!authToken) {
            navigate('/login')
        }
        if (authToken) {
            navigate('/')
        }

    }, [])

    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    const authToken = sessionStorage.getItem('Auth Token')
    return (
      <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
              <CssBaseline />
              <div className="app">
                  <ToastContainer />
                  {authToken ?
                      <Sidebar isSidebar={isSidebar} />
                      :
                      <></>
                  }
                  <main className="content">
                      {authToken ?
                          <Topbar setIsSidebar={setIsSidebar} />
                          :
                          null
                      }
                      <Routes>
                          <Route
                              path='/login'
                              element={
                                  <Form
                                      setEmail={setEmail}
                                      setPassword={setPassword}
                                      handleAction={async () => await handleAction()}
                                  />}
                          />
                          {authToken &&
                              <>
                                  <Route exact path="/" element={<Dashboard />} />
                                  <Route path="/servers" element={<Team />} />
                                  {/*<Route path="/contacts" element={<Contacts />} />*/}
                                  <Route path="/upload_files" element={<Invoices />} />
                                  <Route path="/Config" element={<Forms />} />
                                  <Route path="/faq" element={<FAQ />} />
                                  <Route path="/calendar" element={<Calendar />} />
                                  <Route path="/geography" element={<Geography />} />
                                  <Route path="/Deletes" element={<Line />} />
                                  {/*<Route path="/test" element={<Pie />} />*/}
                                  <Route path="/smanager" element={<Smanager />} />
                                  <Route path="/stats" element={<Stats />} />
                                  <Route path="/stats_history" element={<Statss />} />
                                  <Route path="/stats_history_list" element={<Statsss />} />
                                  <Route path="/multiserversmanage" element={<Multiserver />} />
                                  <Route path="/ConfigEditor" element={<Configedit />} />
                                  <Route path="/Contacts" element={<Contacts />} />
                                  <Route path="/Cookies" element={<Cookies />} />
                              </>
                          }
                      </Routes>
                  </main>
              </div>
          </ThemeProvider>
      </ColorModeContext.Provider>
    );
}

export default App;

import {useNavigate} from "react-router-dom";
import S3 from "aws-sdk/clients/s3";


// aws
const accessKeyId = "AKIAXFD";
const secretAccessKey = "Qg+j9pZZ8mM4I8SGxoN9P7l";

export const s3 = new S3({
    accessKeyId,
    secretAccessKey,
    s3ForcePathStyle: true,
    signatureVersion: "v4",
    connectTimeout: 0,
    httpOptions: { timeout: 0 }
});

export const platform = [
    {
        value: 'spotify',
        label: 'spotify',
    },
    {
        value: 'deezer',
        label: 'deezer',
    },
    {
        value: 'napster',
        label: 'napster',
    },
    {
        value: 'iheart',
        label: 'iheart',
    },
    {
        value: 'ytmusic',
        label: 'ytmusic',
    },
    {
        value: 'amzmusic',
        label: 'amzmusic',
    },
    {
        value: 'soundcloud',
        label: 'soundcloud',
    },
    {
        value: 'youtube',
        label: 'youtube',
    },
    {
        value: 'pandora',
        label: 'pandora',
    },
    {
        value: 'apple',
        label: 'apple',
    },
    {
        value: 'tidal',
        label: 'tidal',
    },
    {
        value: 'tiktok',
        label: 'tiktok',
    },
    {
        value: 'spreaker',
        label: 'spreaker',
    }
];

export const currencies = [
    {
        value: 'true',
        label: 'Yes',
    },
    {
        value: 'false',
        label: 'No',
    }
];

export const captcha_ = [
    {
        value: 1,
        label: 'Antigate',
    },
    {
        value: 2,
        label: '2captcha',
    },
    {
        value: 3,
        label: 'CapMonster Tool',
    }
];

export const proxyType = [
    {
        value: 'http',
        label: 'http',
    },
    {
        value: 'https',
        label: 'https',
    },
    {
        value: 'socks5',
        label: 'socks5',
    }
];

export let mockData_ = [];

export const handleLogout = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    let navigate = useNavigate();
    sessionStorage.removeItem('Auth Token');
    navigate('/login')
}

export const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

export const spotify = 0;


import {Box, Button, FormGroup, TextField, Typography, useTheme} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import Header from "../../Components/Header";
import {LockOutlined} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {delete_ip, get_ips, server_check, server_command, server_restart, server_ss, server_sss} from "../../appconfig";
import {toast} from "react-toastify";
import {handleLogout, platform} from "../../constant";
import {Getfrombase} from "../../firestoremanagement";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// import {useNavigate} from "react-router-dom";
// import {useEffect} from "react";



function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
let red = [{
    value: 'false',
    label: 'No'}, {
    value: 'true',
    label: 'Yes'}];

let redd = [{
    value: 'true',
    label: 'Yes'},
    {
    value: 'false',
    label: 'No'}, ];


let config_ = [{value: 'NONE', label: 'NONE',}]
let module = [
    {
        value: 'browser',
        label: 'CHROME_BRAVE_BROWSER',},
    {
        value: 'app',
        label: 'DESKTOP_APP',},
    {
        value: 'api',
        label: 'API',},
    {
        value: 'apii',
        label: 'API2'}];

let temp_data = []
let temp_datac = []
let temp_datam = []
let temp_datar = []
let temp_datapre = []
let mockDataTeam = [];
let config_d = []
let temp_config = '';
let fload = true;

const Multiserver = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [loading, setLoading] = useState(() => {
        return false
    });

    const [select, setSelection] = useState(() => {
        return [{}];
    });

    const [showa, showall] = useState(() => {
        return false;
    });

    const [temp, tempset] = useState(() => {
        return [];
    });

    let email_ = sessionStorage.getItem('Email')
    let email_x;

    if (email_) {
        email_x = email_;
    }

    if (!email_) {
        handleLogout()
        return;
    }

    const columns = [
        // {
        //     field: "Status",
        //     headerName: "Status",
        //     flex: 0.06,
        //     renderCell: ({ row: { access } }) => {
        //         return (
        //
        //             <Box
        //                 // width="20%"
        //                 // m="0 auto"
        //                 // p="2px"
        //                 // display="flex"
        //
        //                 backgroundColor={
        //                     access === "on"
        //                         ? colors.greenAccent[600]
        //                         :
        //                         access === "off"
        //                             ? colors.grey[700]
        //                             : colors.grey[700]
        //                 }
        //                 // borderRadius="2px"
        //             >
        //                 {access === "off" && <LockOutlined />}
        //                 {access === "on" && <LockOpenOutlinedIcon />}
        //                 {/*<Typography color={colors.grey[100]} sx={{ ml: "2px" }}>*/}
        //                 {/*    {access}*/}
        //                 {/*</Typography>*/}
        //             </Box>
        //         );
        //     },
        // },
        {
            field: "name",
            headerName: "Name",
            flex: 0.1,
        },
        {
            field: "ipaddr",
            headerName: "IP Address",
            flex: 0.16,
            minWidth: 80,
        },
        {
            field: "cpu",
            headerName: "CPU",
            flex: 0.07,
        },
        {
            field: "ram",
            headerName: "RAM",
            flex: 0.07,
        },
        {
            field: "platform",
            headerName: "Platform..",
            flex: 0.09,
        },
        {
            field: "logs",
            headerName: "Logs",
            flex: 0.13,
        },
        {
            field: "platformc",
            headerName: "Choose Platform",
            flex: 0.17,
            renderCell: ({ row: {platform_} }) => {
                return (

                    <TextField
                        id={platform_}
                        select
                        onChange={(data) => {
                            // console.log(data)
                            // temp_data[item.value.toString()] = 'spotify'
                            temp_data[platform_] = data.currentTarget.value.toString()
                            Paho(platform_, data.currentTarget.value.toString())
                            // config_d[platform_] =
                            //     [{value: 'NONE', label: 'NONE',},
                            //     {value: platform_, label: platform_,}]
                            // platformset(data.currentTarget.value+`_${platform_}`)
                        }}
                        value={temp_data[platform_]}
                        name="whichplatform"
                        // error={!!touched.whichplatform && !!errors.whichplatform}
                        // helperText={touched.whichplatform && errors.whichplatform}
                        sx={{gridColumn: "span 2"}}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        {/*<Vaho/>*/}
                        {platform.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}

                    </TextField>
                );
            },
        },
        {
            field: "config",
            headerName: "Config",
            flex: 0.14,
            renderCell: ({ row: {platform_} }) => {
                return (
                    <TextField
                        select
                        onChange={(data) => {
                            // console.log(data)
                            // console.log(platform_)
                            temp_datac[platform_] = data.currentTarget.value.toString()
                            // platformset(data.currentTarget.value+`_${platform_}`)
                        }}
                        name="config"
                        // sx={{gridColumn: "span 2"}}
                        value={temp_datac[platform_]}
                        SelectProps={{
                            native: true,
                        }}>
                        {/*<Cfg />*/}
                        {config_d[platform_] ?
                            config_d[platform_].map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))
                            :
                            config_.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))
                        }
                        {/*{config_.map((option) => (*/}
                        {/*    <option key={option.value} value={option.value}>*/}
                        {/*        {option.label}*/}
                        {/*    </option>*/}
                        {/*))}*/}
                    </TextField>
                );
            },
        },
        {
            field: "module",
            headerName: "Which Module",
            flex: 0.17,
            renderCell: ({ row: { platform_ } }) => {
                return (
                    <TextField
                        select
                        onChange={(data) => {
                            // console.log(data)
                            // console.log(platform_)
                            temp_datam[platform_] = data.currentTarget.value.toString()
                            // platformset(data.currentTarget.value+`_${platform_}`)
                        }}
                        name="module"
                        value={temp_datam[platform_]}
                        sx={{gridColumn: "span 1"}}
                        SelectProps={{
                            native: true,
                        }}
                    >
                        {module.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                );
            },

        },
        {
            field: "re",
            headerName: "ReDownloads",
            flex: 0.12,
            renderCell: ({ row: { platform_ } }) => {
                return (
                    <TextField
                        select
                        onChange={(data) => {
                            // console.log(data)
                            // console.log(platform_)
                            temp_datar[platform_] = data.currentTarget.value.toString()
                            // platformset(data.currentTarget.value+`_${platform_}`)
                        }}
                        name="red"
                        sx={{gridColumn: "span 2"}}
                        SelectProps={{
                            native: true,
                        }}
                    >{red.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                );
            },

        },
        {
            field: "pre",
            headerName: "StopPreviousSession",
            flex: 0.12,
            renderCell: ({ row: { platform_ } }) => {
                return (
                    <TextField
                        select
                        onChange={(data) => {
                            // console.log(data)
                            // console.log(platform_)
                            temp_datapre[platform_] = data.currentTarget.value.toString()
                            // platformset(data.currentTarget.value+`_${platform_}`)
                        }}
                        name="pre"
                        sx={{gridColumn: "span 2"}}
                        SelectProps={{
                            native: true,
                        }}
                    >{redd.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                    </TextField>
                );
            },

        },
        {
            field: "system",
            headerName: "System Info",
            flex: 0.15,

        },
        {
            field: "ss",
            headerName: "Screen",
            flex: 0.14,
            renderCell: ({ row: { platform_ } }) => {
                return (
                    <>
                        <Button id={platform_+"_BUTTON"} onClick={async () => {
                            await handless(platform_)
                            await sleep(10000)
                            document.getElementById(platform_+"_BUTTON").disabled=false;
                        }}
                                type="submit" color="warning" variant="contained">
                            getSS
                        </Button>
                    </>

                );
            },
        },
    ];
    // server_ss('127.0.0.1').then(r => r)

    const Ipsd = async () =>{
        mockDataTeam = [];
        try{
            document.getElementById("REFRESH_BUTTON").disabled=true;
        }catch (e){}

        let server = await get_ips(email_x)

        // server.push({
        //     value: '192.168.1.10',
        //     label: '192.168.1.10',
        // })

        for (let item of server){
            if (item.value === "NONE") continue;
            if(item.value.toString() in temp_data){
            }else{
                temp_data[item.value.toString()] = 'spotify'
            }
            if(item.value.toString() in temp_datac){
            }else{
                temp_datac[item.value.toString()] = 'NONE'
            }
            if(item.value.toString() in temp_datam){
            }else{
                temp_datam[item.value.toString()] = 'browser'
            }
            if(item.value.toString() in temp_datar){
            }else{

                temp_datar[item.value.toString()] = 'false'
            }
            if(item.value.toString() in temp_datapre){
            }else{
                temp_datapre[item.value.toString()] = 'false'
            }
            // let tempe = await server_check(item.value)
            // temp_data[item.value.toString()] = 'spotify'
            server_check(item.value).then((tempe) => {

                if (!tempe){
                    if(showa){
                        mockDataTeam = [...mockDataTeam, {
                            name: 'none',
                            ipaddr: item.value.toString(),
                            platform_: item.value.toString(),
                            cpu: 'none',
                            runn: "none",
                            ram: 'none',
                            platform: 'none',
                            logs: 'none',
                            access: 'off',
                            system: 'none'
                        }]
                    }
                }
                else{
                    try {
                        let rucof = 'NONE'
                        try{
                            rucof = tempe.data.config.toString()
                        }catch (e) {
                        }
                        getconfig(item.value.toString(), tempe.data.platform.toString())

                        temp_data[item.value.toString()] = tempe.data.platform.toString()
                        temp_datac[item.value.toString()] = rucof
                        try{

                            if (tempe.data.module.toString() !== "false"){
                                temp_datam[item.value.toString()] = tempe.data.module.toString()
                            }
                        }catch (e) {
                        }
                        mockDataTeam = [...mockDataTeam, {
                            name: tempe.data.name.toString(),
                            ipaddr: item.value.toString(),
                            platform_: item.value.toString(),
                            cpu: tempe.data.cpu.toString()+'%',
                            ram: tempe.data.ram.toString()+'%',
                            platform: tempe.data.platform.toString(),
                            logs: tempe.data.logs.toString(),
                            system: 'CPU: '+tempe.data.system.toString()+' RAM: '+tempe.data.ram_info.toString(),
                            access: 'on',
                        }]
                        // mockDataTeam.push({
                        //     name: tempe.data.name.toString(),
                        //     ipaddr: item.value.toString(),
                        //     platform_: item.value.toString(),
                        //     cpu: tempe.data.cpu.toString()+'%',
                        //     ram: tempe.data.ram.toString()+'%',
                        //     platform: tempe.data.platform.toString(),
                        //     logs: tempe.data.logs.toString(),
                        //     system: 'CPU: '+tempe.data.system.toString()+' RAM: '+tempe.data.ram_info.toString(),
                        //     access: 'on',
                        // })
                    } catch (e) {
                        console.log(e)
                    }
                }

                tempset(mockDataTeam)

            })

            // if (!tempe){
            //     mockDataTeam.push({
            //         name: 'none',
            //         ipaddr: item.value.toString(),
            //         platform_: item.value.toString(),
            //         cpu: 'none',
            //         runn: "none",
            //         ram: 'none',
            //         platform: 'none',
            //         logs: 'none',
            //         access: 'off',
            //         system: 'none'
            //     })
            // }
            // else{
            //     try {
            //         let rucof = 'NONE'
            //         try{
            //             rucof = tempe.data.config.toString()
            //         }catch (e) {
            //         }
            //
            //         temp_data[item.value.toString()] = tempe.data.platform.toString()
            //         temp_datac[item.value.toString()] = rucof
            //         try{
            //             temp_datam[item.value.toString()] = tempe.data.module.toString()
            //         }catch (e) {
            //         }
            //
            //         mockDataTeam.push({
            //             name: tempe.data.name.toString(),
            //             ipaddr: item.value.toString(),
            //             platform_: item.value.toString(),
            //             cpu: tempe.data.cpu.toString()+'%',
            //             ram: tempe.data.ram.toString()+'%',
            //             platform: tempe.data.platform.toString(),
            //             logs: tempe.data.logs.toString(),
            //             system: 'CPU: '+tempe.data.system.toString()+' RAM: '+tempe.data.ram_info.toString(),
            //             access: 'on',
            //         })
            //     } catch (e) {
            //         console.log(e)
            //     }
            // }
        }
        await sleep(6000)
        document.getElementById("REFRESH_BUTTON").disabled=false;


    }

    const YourComponent = () => {
        useEffect(() => {
            Ipsd().then(e => e)
        },[fload])
    }
    YourComponent()

    const handless = async (platform_) => {
        try {
            for (let li of mockDataTeam){
                if (li.ipaddr === platform_ && li.access === 'off'){
                    console.log('system is offline')
                    return false
                }
            }
            document.getElementById(platform_+"_BUTTON").disabled=true;
            toast.info(`WAIT GETTING SCREEN SHOTS FROM ${platform_}`)
            let i = 1, n = 3;
            while (i <= n) {
                i++
                const data = await server_ss(platform_)
                if(!data.url || !data){
                    toast.info(`FAIL TO GET SS RETRYING ${platform_}`)
                }else{
                    window.open(data.url, `${platform_}`, 'noreferrer' );
                    console.log(data.url)
                    break
                }

            }
            return true;

        } catch (e) {
            console.log(e)
        }
    };

    const handleFormSubmits = async (fg) => {
        try {
            setLoading(true);
            let action = "start"
            if(!fg){
                action = "stop"
            }
            // console.log(temp_data)
            // console.log(temp_datac)
            // console.log(temp_datam)
            // console.log(temp_datar)
            // console.log(value={temp_data[platform_]})
            for (const data of select) {
                for (let li of mockDataTeam) {

                    if (li.ipaddr === data && li.access === 'on') {
                        if(temp_datac[li.ipaddr] === 'NONE') {
                            toast.error('No config Selected')
                            return
                        }
                        server_command(li.ipaddr, action, temp_data[li.ipaddr],

                            temp_datac[li.ipaddr], temp_datar[li.ipaddr], temp_datam[li.ipaddr],
                            temp_datapre[li.ipaddr]).then(e => {
                            if(e){
                                toast.success('COMMAND HAS BEEN SEND TO SERVER'+` ${data}`)
                            }
                            else{
                                toast.error('ERROR TO SEND COMMAND (RETRY)'+` ${data}`)
                            }
                        }).catch(e => { console.log(e);
                            toast.error('ERROR TO SEND COMMAND'+` ${data}`)})
                    }
                }
                await sleep(1000)
            }
            // toast.success(`WAIT FOR 10 SEC`)
            // await sleep(10000)
            // toast.success(`RESTARTED LIST`)
            // await Ipsd()
            await sleep(5000)
            setLoading(false);
        } catch (e) {
            console.log(e)
        }
    };

    const handleFormSubmitre = async () => {
        try {
            for (const data of select) {
                for (let li of mockDataTeam) {
                    if (li.ipaddr === data) {
                        await delete_ip(email_x, li.ipaddr).then()
                    }
                }
            }
            toast.success(`REMOVED LIST`)
            // tempset(3)
            await Ipsd()
        } catch (e) {
            console.log(e)
        }
    };

    const handleFormSubmitx = async (fg) => {
        try {
            setLoading(true);
            for (const data of select) {
                for (let li of mockDataTeam) {
                    if (li.ipaddr === data) {
                        await server_restart(li.ipaddr, fg).then()
                    }

                }
                await sleep(1000)
                // mockDataTeam.forEach((li) => {
                //     if (li.ipaddr === data){
                //         server_restart(li.ipaddr).then()
                //     }
                // })
            }
            toast.success(`WAIT FOR 10 SEC`)
            await sleep(10000)
            toast.success(`RESTARTED LIST`)
            await Ipsd()
            setLoading(false);
        } catch (e) {
            console.log(e)
        }
    };

    const getconfig = async (platform_, whichplatform) => {
        temp_config = await Getfrombase(email_, whichplatform)

        if (temp_config[0]) {
            config_d[platform_]  = temp_config[0]

        } else {
            config_d[platform_] = [{value: 'NONE', label: 'NONE',}]
        }

    }

    const Paho = (platform_, whichplatform) => {
        const prase = async () => {
            temp_config = await Getfrombase(email_, whichplatform)

            if (temp_config[0]) {
                config_d[platform_]  = temp_config[0]
                // temp_config[1].forEach(fg =>{
                //     if (fg.configname === values.config){
                //         //showconfig = `${JSON.stringify([fg])}`;
                //         // convertedObject = [Object.keys(fg), Object.values(fg)];
                //         let tempc = []
                //         // configtoshowset(tempc);
                //         Object.keys(fg).forEach((vl, index) => {
                //             tempc.push({
                //                 value: fg[vl],
                //                 label: vl.toString(),
                //             })
                //         })
                //
                //         configtoshowset(tempc);
                //         // configtoshow.map((titleList => console.log(titleList.value, titleList.label)))
                //         terr(true)
                //     }
                //     // else{
                //     //     terr(false)
                //     // }
                // })

            } else {
                config_d[platform_] = [{value: 'NONE', label: 'NONE',}]
            }

        }
        prase().then()
    };

    return (
        <Box m="20px">
            <Header title="MULTIPLE SERVERS MANAGEMENT" subtitle="ACTIVE SERVER | CLICK REFRESH TO EXTRACT ALL OF YOURS
             SERVERS" />

            <Box
                m="30px 0 0 0"
                height="70vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}>
                <FormControlLabel
                    onChange={e =>{
                    showall(e.target.checked)}}
                    control={<Checkbox />} label="Show All" />
                <DataGrid checkboxSelection
                          getRowHeight={() => 'auto'}
                          rowThreshold={0}
                          rows={temp} columns={columns}
                          getRowId={(row) =>  row.ipaddr}
                          onSelectionModelChange={(newSelection) => {
                              setSelection(newSelection);
                              // console.log(newSelection)
                          }}/>

                <Box
                    justifyContent="end" mt="40px" display="grid"
                     gap="30px"
                     gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                     sx={{
                         "& > div": { gridColumn: "span 4" },
                     }}>
                    <Button disabled={loading} onClick={async () => await handleFormSubmits(true)}
                            type="submit" color="secondary" variant="contained">
                        Start Bot In.
                    </Button>

                    <Button disabled={loading} onClick={async () => handleFormSubmits(false)}
                            type="submit" color="warning" variant="contained">
                        Stop Bot in.
                    </Button>
                    <Button onClick={async () => handleFormSubmitre()}
                            type="submit" color="error" variant="contained">
                        Remove Selected.
                    </Button>

                    <Button id="REFRESH_BUTTON" onClick={async () => {
                        await Ipsd()

                    }} type="submit" color="info" variant="contained">
                        Refresh Chart.
                    </Button>
                    <Button disabled={loading} onClick={async () => handleFormSubmitx(true)}
                            type="submit" color="secondary" variant="contained">
                        Restart Server.
                    </Button>
                    <Button disabled={loading} onClick={async () => handleFormSubmitx(false)}
                            type="submit" color="warning" variant="contained">
                        Restart Bots in.
                    </Button>
                    <Typography variant="h5" color={colors.redAccent[500]} sx={{gridColumn: "span 3"}}>
                        Restart Bots in. restart bot with same config or old running config
                         use Start Bot in. to start with new config..
                    </Typography>
                    <Typography variant="h7" color={colors.grey[500]} sx={{gridColumn: "span 4"}}>
                        AFTER CLICKING WAIT FOR PAGE TO REFRESH DON'T SPAM WITH CLICKS
                        {`\n`} WAIT IT LL TAKE TIME TO EXTRACT!!
                    </Typography>


                </Box>

            </Box>

        </Box>
    );
};

export default Multiserver;

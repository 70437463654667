import {Box, Button, TextField, Typography, useTheme} from "@mui/material";
import {Formik, useFormikContext} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../Components/Header";
import {useEffect, useState} from "react";
import {tokens} from "../../theme";
import {handleLogout, platform, s3} from "../../constant";
import {storage} from "../../App";
import {
    listAll,
    ref,
    deleteObject,
} from "firebase/storage";
import {delete_db, Getfrombase} from "../../firestoremanagement";
import {delete_file, get_file_list} from "../../appconfig";
// import { v4 } from "uuid";

const Formx = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [imgUrl, setImgUrl] = useState(() =>{
        return null;
    });

    let email_ = sessionStorage.getItem('Email')
    let email_x;
    if (email_) {
        email_x = email_;
    }
    if (!email_) {
        handleLogout()
        return;
    }

    const handleFormSubmit = (values) => {
        (async ()=> {
            const file = values.userdata
            const filex = values.songurl
            if (file !== "NONE") {
                if (appwr){
                    await delete_file(email_x, 'userdata', values.whichplatform, file).then(e =>{
                        if(!e){
                            alert("ERROR PLEASE NO FILES OR CONNECTION")
                        }
                    })
                }
                else{
                    const imageRef = ref(storage, `${email_x}/${values.whichplatform}/userdata/${file}`);
                    deleteObject(imageRef).then(() => {
                    }).catch(() => {
                        alert("ERROR PLEASE NO FILES OR CONNECTION")
                    });
                }
            }

            if (filex !== 'NONE') {
                if (appwr){
                    await delete_file(email_x, 'links', values.whichplatform, filex).then(e =>{
                        if(!e){
                            alert("ERROR PLEASE NO FILES OR CONNECTION")
                        }
                    })
                }
                else {
                    const imageRef2 = ref(storage, `${email_x}/${values.whichplatform}/links/${filex}`);
                    deleteObject(imageRef2).then(() => {
                    }).catch(() => {
                        alert("ERROR PLEASE NO FILES OR CONNECTION")
                    });
                }

            }

            if (values.proxy !== 'NONE') {
                if (appwr){
                    await delete_file(email_x, 'proxy', values.whichplatform, values.proxy).then(e =>{
                        if(!e){
                            alert("ERROR PLEASE NO FILES OR CONNECTION")
                        }
                    })
                }
                else{
                    deleteObject(ref(storage, `${email_x}/${values.whichplatform}/proxy/${values.proxy}`)).then(() => {
                    }).catch(() => {
                        alert("ERROR PLEASE NO FILES OR CONNECTION")
                    });
                }

            }
            if (values.config !== 'NONE'){
                await delete_db(email_, values.whichplatform, values.config)
            }
            if (temvalue !== values.whichplatform) {
                Userdata_ = [{
                    value: 'NONE',
                    label: 'NONE',
                }];
                Links = [{
                    value: 'NONE',
                    label: 'NONE',
                }];
                proxy_ = [{
                    value: 'NONE',
                    label: 'NONE',
                }]
                if (appwr){
                    await get_file_list(email_x, 'userdata', values.whichplatform).then(
                        e => {
                            Userdata_ = e
                        })
                    await get_file_list(email_x, 'links', values.whichplatform).then(
                        e => {
                            Links = e
                        })
                    await get_file_list(email_x, 'proxy', values.whichplatform).then(
                        e => {
                            proxy_ = e
                        })

                }
                temp_config = await Getfrombase(email_, values.whichplatform)
                if (temp_config[0]) {
                    config = temp_config[0]
                } else {
                    config = [{
                        value: 'NONE',
                        label: 'NONE',
                    }];
                }


            }
            initialValues.whichplatform = temvalue = values.whichplatform;
            setImgUrl(true)

        })()

    };

    let temvalue = initialValues.whichplatform;

    let Userdata_ = [{
        value: 'NONE',
        label: 'NONE',
    }];
    let Links = [{
        value: 'NONE',
        label: 'NONE',
    }];
    let proxy_ = [{
        value: 'NONE',
        label: 'NONE',
    }];
    let config = [{
        value: 'NONE',
        label: 'NONE',}];

    let aws = false;
    let appwr = true;
    let temp_config = '';
    // let temp_config = Getfromcollection(email_, initialValues.whichplatform)

    async function ptemp (){
        temp_config = await Getfrombase(email_, initialValues.whichplatform)
        if(temp_config[0]){
            config = temp_config[0]
        }
    }
    ptemp().then(r => r)

    if (!aws) {
        if (appwr){
            (async () =>{
                await get_file_list(email_x, 'userdata', initialValues.whichplatform).then(
                    e => {
                        Userdata_ = e
                    }).catch(e => {
                    console.log(e)
                })
                await get_file_list(email_x, 'links', initialValues.whichplatform).then(
                    e => {
                        Links = e
                    }).catch(e => {
                    console.log(e)
                })
                await get_file_list(email_x, 'proxy', initialValues.whichplatform).then(
                    e => {
                        proxy_ = e
                    }).catch(e => {
                    console.log(e)
                })
            })()

        }
        else {
            const imagesListRef = ref(storage, `${email_x}/${initialValues.whichplatform}/userdata`);
            const imagesListRef2 = ref(storage, `${email_x}/${initialValues.whichplatform}/links`);
            try {
                listAll(imagesListRef).then((response) => {
                    response.items.forEach((item) => {
                        Userdata_.push({
                            value: item.name.toString(),
                            label: item.name.toString(),
                        })
                    });
                });
                listAll(imagesListRef2).then((response) => {
                    response.items.forEach((item) => {
                        Links.push({
                            value: item.name.toString(),
                            label: item.name.toString(),
                        })
                    });
                });
                listAll(ref(storage, `${email_x}/${initialValues.whichplatform}/proxy`)).
                then((response) => {
                    response.items.forEach((item) => {
                        proxy_.push({
                            value: item.name.toString(),
                            label: item.name.toString(),
                        })
                    });
                });
            }
            catch (e) {
                console.log(e)
            }
        }
    }
    else {
        s3.listObjectsV2({
            Bucket: "zeroonzeroapp",
            Delimiter: '/',
            MaxKeys: 100,
            Prefix: `${email_x}/${initialValues.whichplatform}/userdata/`,
        }, (err, data) => {if (err) {
            console.log(err, err.stack);
        } else {
            try{
                for (let index = 0; index < data.Contents.length; index++) {
                    const item = data.Contents[index].Key.toString();
                    Userdata_.push({
                        value: item.split('/')[3],
                        label: item.split('/')[3],
                    })
                }
            }catch (e){
                alert(e)
            }

        }})
        s3.listObjectsV2({
            Bucket: "zeroonzeroapp",
            Delimiter: '/',
            MaxKeys: 100,
            Prefix: `${email_x}/${initialValues.whichplatform}/links/`,
        }, (err, data) => {if (err) {
            console.log(err, err.stack);
        } else {
            try{
                for (let index = 0; index < data.Contents.length; index++) {
                    const item = data.Contents[index].Key.toString();
                    Links.push({
                        value: item.split('/')[3],
                        label: item.split('/')[3],
                    })
                }
            }catch (e){
                alert(e)
            }
        }})
        s3.listObjectsV2({
            Bucket: "zeroonzeroapp",
            Delimiter: '/',
            MaxKeys: 100,
            Prefix: `${email_x}/${initialValues.whichplatform}/proxy/`,
        }, (err, data) => {if (err) {
            console.log(err, err.stack);
        } else {
            try{
                for (let index = 0; index < data.Contents.length; index++) {
                    const item = data.Contents[index].Key.toString();
                    proxy_.push({
                        value: item.split('/')[3],
                        label: item.split('/')[3],
                    })
                }
            }catch (e){
                alert(e)
            }

        }})

    }


    const Vaho = () => {
        const {values} = useFormikContext();
        useEffect(() => {
            initialValues.whichplatform = values.whichplatform;
            async function prase (){
                if (temvalue !== values.whichplatform) {
                    Userdata_ = [{
                        value: 'NONE',
                        label: 'NONE',
                    }];
                    Links = [{
                        value: 'NONE',
                        label: 'NONE',
                    }];
                    proxy_ = [{
                        value: 'NONE',
                        label: 'NONE',
                    }]
                    if (!aws){
                        if (appwr){
                            await get_file_list(email_x, 'userdata', values.whichplatform).then(
                                e => {
                                    Userdata_ = e
                                })
                            await get_file_list(email_x, 'links', values.whichplatform).then(
                                e => {
                                    Links = e
                                })
                            await get_file_list(email_x, 'proxy', values.whichplatform).then(
                                e => {
                                    proxy_ = e
                                })

                        }
                        else {
                            const imagesListRef = ref(storage, `${email_x}/${values.whichplatform}/userdata`);
                            const imagesListRef2 = ref(storage, `${email_x}/${values.whichplatform}/links`);
                            try {
                                listAll(imagesListRef).then((response) => {
                                    response.items.forEach((item) => {
                                        Userdata_.push({
                                            value: item.name.toString(),
                                            label: item.name.toString(),
                                        })
                                    });
                                });
                                listAll(imagesListRef2).then((response) => {
                                    response.items.forEach((item) => {
                                        Links.push({
                                            value: item.name.toString(),
                                            label: item.name.toString(),
                                        })
                                    });
                                });
                                listAll(ref(storage, `${email_x}/${values.whichplatform}/proxy`)).
                                then((response) => {
                                    response.items.forEach((item) => {
                                        proxy_.push({
                                            value: item.name.toString(),
                                            label: item.name.toString(),
                                        })
                                    });
                                });

                            }
                            catch (e) {
                                console.log(e)
                            }
                        }

                        temp_config = await Getfrombase(email_, values.whichplatform)
                        if (temp_config[0]) {
                            config = temp_config[0]
                        } else {
                            config = [{
                                value: 'NONE',
                                label: 'NONE',
                            }];
                        }
                    }
                    else {
                        s3.listObjectsV2({
                            Bucket: "zeroonzeroapp",
                            Delimiter: '/',
                            MaxKeys: 100,
                            Prefix: `${email_x}/${values.whichplatform}/userdata/`,
                        }, (err, data) => {if (err) {
                            console.log(err, err.stack);
                        } else {
                            try{
                                for (let index = 0; index < data.Contents.length; index++) {
                                    const item = data.Contents[index].Key.toString();
                                    Userdata_.push({
                                        value: item.split('/')[3],
                                        label: item.split('/')[3],
                                    })
                                }
                            }catch (e){
                                alert(e)
                            }

                        }})
                        s3.listObjectsV2({
                            Bucket: "zeroonzeroapp",
                            Delimiter: '/',
                            MaxKeys: 100,
                            Prefix: `${email_x}/${values.whichplatform}/proxy/`,
                        }, (err, data) => {if (err) {
                            console.log(err, err.stack);
                        } else {
                            try{
                                for (let index = 0; index < data.Contents.length; index++) {
                                    const item = data.Contents[index].Key.toString();
                                    proxy_.push({
                                        value: item.split('/')[3],
                                        label: item.split('/')[3],
                                    })
                                }
                            }catch (e){
                                alert(e)
                            }

                        }})

                        s3.listObjectsV2({
                            Bucket: "zeroonzeroapp",
                            Delimiter: '/',
                            MaxKeys: 100,
                            Prefix: `${email_x}/${values.whichplatform}/links/`,
                        }, (err, data) => {if (err) {
                            console.log(err, err.stack);
                        } else {
                            try{
                                for (let index = 0; index < data.Contents.length; index++) {
                                    const item = data.Contents[index].Key.toString();
                                    Links.push({
                                        value: item.split('/')[3],
                                        label: item.split('/')[3],
                                    })
                                }
                            }catch (e){
                                alert(e)
                            }
                        }})

                    }

                }

                initialValues.whichplatform = temvalue = values.whichplatform;
            }
            prase().then(r => r)
        }, [values]);
        return null;
    };

    return (
        <Box m="20px">
            <Header title="_DELETE FILES & CONFIG_" subtitle="DELETE CHOSEN FILES & CONFIG"/>

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="20px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                            }}
                        >
                            <TextField
                                select
                                label="which_platform"
                                onBlur={handleBlur}
                                value={values.whichplatform}
                                onChange={handleChange}
                                name="whichplatform"
                                error={!!touched.whichplatform && !!errors.whichplatform}
                                helperText={touched.whichplatform && errors.whichplatform}
                                sx={{gridColumn: "span 1"}}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <Vaho/>
                                {platform.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <Typography variant="h4" color={colors.redAccent[500]} sx={{gridColumn: "span 4"}}>
                                SELECT FILE'S OR CONFIG WHICH YOU WANT TO DELETE FROM CHOSEN PLATFORM
                            </Typography>
                            <Typography variant="h4" color={colors.redAccent[500]} sx={{gridColumn: "span 4"}}>
                                UPLOADED FILES OR CONFIG WILL BE UPDATED IN LIST BOX OF SECTION
                            </Typography>
                            <Typography variant="h4" color={colors.redAccent[500]} sx={{gridColumn: "span 4"}}>
                                LEAVE SELECTION AS NONE IF YOU DON'T WANT TO DELETE THAT FILE OR CONFIG
                            </Typography>
                            <TextField
                                select
                                label="Email and Pass"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.userdata}
                                name="userdata"
                                error={!!touched.userdata && !!errors.userdata}
                                helperText={touched.userdata && errors.userdata}
                                sx={{gridColumn: "span 2"}}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {Userdata_.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <TextField
                                select
                                label="Song Urls"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.songurl}
                                name="songurl"
                                error={!!touched.songurl && !!errors.songurl}
                                helperText={touched.songurl && errors.songurl}
                                sx={{gridColumn: "span 2"}}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {Links.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <TextField
                                select
                                label="Proxy Files"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.proxy}
                                name="proxy"
                                error={!!touched.proxy && !!errors.proxy}
                                helperText={touched.proxy && errors.proxy}
                                sx={{gridColumn: "span 2"}}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {proxy_.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <TextField
                                select
                                label="Config"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.config}
                                name="config"
                                error={!!touched.config && !!errors.config}
                                helperText={touched.config && errors.config}
                                sx={{gridColumn: "span 2"}}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {config.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>

                            {imgUrl &&
                                <Typography variant="h2" color={colors.greenAccent[500]}>
                                    DONE !!
                                </Typography>
                            }

                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <div>
                                <Button type="submit" color="secondary" variant="contained">
                                    Delete Stuff.
                                </Button>
                            </div>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    );
};


const checkoutSchema = yup.object().shape({
    whichplatform: yup.string().required("required"),
    // userdata: yup.string().file()
});

const initialValues = {
    whichplatform: 'spotify',
    userdata: "NONE",
    songurl: "NONE",
    proxy: "NONE",
    config: 'NONE'
};

export default Formx;

// const handleFormSubmitaws = (values) => {
//     const file = values.userdata
//     const filex = values.songurl
//     const fileY = values.Proxy
//
//     if (file !== "NONE") {
//         s3.deleteObject({
//             Bucket: "zeroonzeroapp",
//             Key: `${email_x}/${values.whichplatform}/userdata/${file}`,
//         }, (err, data) => {
//             if (err)  alert("ERROR PLEASE NO FILES OR CONNECTION EANDP");
//             if (data) console.log(data);
//         });
//     }
//
//     if (filex !== 'NONE') {
//         s3.deleteObject({
//             Bucket: "zeroonzeroapp",
//             Key: `${email_x}/${values.whichplatform}/links/${filex}`,
//         }, (err, data) => {
//             if (err)  alert("ERROR PLEASE NO FILES OR CONNECTION");
//             if (data) console.log(data);
//         });
//     }
//
//     if (fileY !== 'NONE') {
//         s3.deleteObject({
//             Bucket: "zeroonzeroapp",
//             Key: `${email_x}/${values.whichplatform}/proxy/${fileY}`,
//         }, (err, data) => {
//             if (err)  alert("ERROR PLEASE NO FILES OR CONNECTION");
//             if (data) console.log(data);
//         });
//     }
//
//     setImgUrl(true)
// };
import {Box, Button, TextField, Typography, useTheme} from "@mui/material";
import Header from "../../Components/Header";
import {useState} from "react";
import {tokens} from "../../theme";
import {handleLogout, platform} from "../../constant";
import {Formik} from "formik";
import dayjs from 'dayjs';
import useMediaQuery from "@mui/material/useMediaQuery";
import {stats_date} from "../../appconfig";
import {toast} from "react-toastify";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";

const Statsss = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    let [ter, terr] = useState(() => {
        return false;
    })
    let [configtoshow, configtoshowset] = useState(() => {
        return [];
    })
    let [columnstoshow, columnsoshowset] = useState(() => {
        return [];
    })


    let email_ = sessionStorage.getItem('Email')
    let email_x;

    if (email_) {
        email_x = email_;
    }

    if (!email_x) {
        handleLogout()
        return;
    }

    const handleFormSubmit = async (fg) => {
        try {
            let columns = [
                {
                    field: "index",
                    headerName: "index",
                    flex: 0.1,
                },
                {
                    field: "links",
                    headerName: "Links",
                    flex: 0.7,
                },

            ];

            // const min_date = dayjs(fg.date).format("DD-MM-YYYY")
            // const max_date = dayjs(fg.date_max).format("DD-MM-YYYY")
            if(!fg.date && !fg.date_max){
                toast.error('PLEASE SET DATE CORRECTLY')
                return;
            }
            //date
            const listDate = [];
            const listDate_new = [];
            let linkset = []
            let total_db = []

            const startDate =fg.date;
            const endDate = fg.date_max;
            const dateMove = new Date(startDate);
            let strDate = startDate;
            if (strDate === endDate){
                listDate.push(dayjs(strDate).format("DD-MM-YYYY"));
            }
            let cnt = 0
            while (strDate < endDate) {
                strDate = dateMove.toISOString().slice(0, 10);
                listDate.push(dayjs(strDate).format("DD-MM-YYYY"));
                // console.log(strDate)
                dateMove.setDate(dateMove.getDate() + 1);
                cnt++
                if (cnt > 7){
                    break
                }
            }

            let cnty = 0
            let last = {'links': 'TOTALS', 'index': 0, 'total': 0}
            for (let cd of listDate){
                last[cd] = 0
                // await stats_date(email_x, fg.whichplatform, cd).then(dfd => {
                //
                //     if(dfd){
                //         listDate_new.push(cd)
                //         columns = [...columns, {
                //             field: cd.toString(),
                //             headerName: cd.toString(),
                //             flex: 0.2,
                //         }]
                //         for (let gg of dfd.documents){
                //             if (!linkset.includes(gg.link)) {
                //                 linkset.push(gg.link)
                //                 total_db[gg.link] = []
                //             }
                //             total_db[gg.link].push({'streams': gg.streams, 'date': cd})
                //         }
                //     }
                // }).catch( e => cnty ++)
                const dfd = await stats_date(email_x, fg.whichplatform, cd)
                if(dfd){
                    listDate_new.push(cd)
                    columns = [...columns, {
                        field: cd.toString(),
                        headerName: cd.toString(),
                        flex: 0.2,
                    }]
                    for (let gg of dfd.documents){
                        if (!linkset.includes(gg.link)) {
                            linkset.push(gg.link)
                            total_db[gg.link] = []
                        }
                        total_db[gg.link].push({'streams': gg.streams, 'date': cd})
                    }

                }
            }
            let data_push = []
            let index_ = 1

            for (let ff of linkset){

                let temp_data = {'links': ff, 'index': index_, 'total': 0}
                index_++
                for (let fg of total_db[ff]){
                    listDate_new.forEach((ix) => {
                        if (ix === fg.date){
                            temp_data[ix]= fg.streams.toString()
                            try {
                                temp_data['total'] += parseInt(fg.streams)
                            }catch (e) {
                            }
                            try{

                                last[ix] += parseInt(fg.streams)
                                last['total'] += parseInt(fg.streams)
                            }catch (e) {
                            }

                        }
                    })}
                data_push.push(
                    temp_data
                )
            }

            columns = [...columns, {
                field: "total",
                headerName: "Total",
                flex: 0.2,
            }]
            last['index'] = '010'
            data_push.push(
                last
            )
            columnsoshowset(columns)
            configtoshowset(data_push);
            terr(true)


            // console.log(linkset)
            // console.log(total_db)
            // console.log(columns)

            // let data_push = []
            // let index_ = 1
            // for (let ff of linkset){
            //
            //     let temp_data = {'links': ff, 'index': index_}
            //     index_++
            //     for (let fg of total_db[ff]){
            //         listDate_new.forEach((ix) => {
            //             if (ix === fg.date){
            //                 temp_data[ix]= fg.streams.toString()
            //             }else{
            //                 if(!temp_data[ix]){
            //                     temp_data[ix] = 0
            //                 }
            //             }
            //         })
            //     }
            //
            //     data_push.push(
            //         temp_data
            //     )
            // }
            //
            // columnsoshowset(columns)
            // configtoshowset(data_push);
            // terr(true)


        } catch (e) {
            console.log(e)
        }
    };


    const Cfg = () => {
        return (
            <Box
                m="50px 0 0 0"
                height="60vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                    },
                }}>
                <Typography variant="h5" color={colors.redAccent[500]} sx={{gridColumn: "span 4"}}>
                    .....................
                </Typography>
                <DataGrid
                    disableSelectionOnClick={true}
                    getRowId={(row) =>  row.links}
                    components={{ Toolbar: GridToolbar }}
                    rows={configtoshow} columns={columnstoshow}/>
            </Box>
        )
    };


    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const initialValues = {
        whichplatform: 'spotify',
        date:  yyyy + '-' + mm + '-' + dd,
        date_max:  yyyy + '-' + mm + '-' + dd,
    };


    return (
        <Box m="10px">
            <Header title="STATS DATA BY LINKS" subtitle="CLICK SUBMIT TO EXTRACT ALL OF YOURS STATS" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                            }}
                        >
                            <Typography variant="h5" color={colors.greenAccent[500]} sx={{gridColumn: "span 4"}}>
                                SET DATE START FROM TO END DATE TO GET STATS..
                            </Typography>
                            <TextField
                                select
                                label="which_platform"
                                onBlur={handleBlur}
                                value={values.whichplatform}
                                onChange={handleChange}
                                name="whichplatform"
                                error={!!touched.whichplatform && !!errors.whichplatform}
                                helperText={touched.whichplatform && errors.whichplatform}
                                sx={{ gridColumn: "span 1" }}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {platform.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <Typography variant="h6" color={colors.greenAccent[500]} sx={{gridColumn: "span 3"}}>
                                KEEP RANGE OF 7 DAYS ONLY IT WON'T SHOW MORE THEN 7 DAYS STATS FROM START DATE.
                            </Typography>

                            <TextField
                                type="date"
                                label="Start Date"
                                onBlur={handleBlur}
                                value={values.date}
                                // onChange={(event) => {
                                //     setFieldValue('date', event.currentTarget.value)
                                // }}
                                onChange={handleChange}
                                name="date"
                                error={!!touched.date && !!errors.date}
                                helperText={touched.date && errors.date}
                                sx={{gridColumn: "span 1"}}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true, required: true }}
                            />

                            <TextField
                                type="date"
                                label="End Date"
                                onBlur={handleBlur}
                                value={values.date_max}

                                // onChange={(event) => {
                                //     setFieldValue('date', event.currentTarget.value)
                                // }}
                                onChange={handleChange}
                                name="date_max"
                                error={!!touched.date_max && !!errors.date_max}
                                helperText={touched.date_max && errors.date_max}
                                sx={{gridColumn: "span 1"}}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <Box display="flex" justifyContent="start" mt="20px">
                                <Button type="submit" color="secondary" variant="contained">
                                    Get All Stats
                                </Button>
                            </Box>
                        </Box>
                        {ter &&
                            <>
                                <Cfg/>
                                {/*{configtoshow.map((titleList => console.log(titleList.value, titleList.label)))}*/}
                            </>}
                    </form>
                )}
            </Formik>



        </Box>
    );
};



export default Statsss;

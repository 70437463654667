import { collection, doc, getDocs, deleteDoc , query, setDoc } from 'firebase/firestore'
import {fdatabase, firestore} from "./App";
import {toast} from "react-toastify";
import { onValue, ref, set, remove } from 'firebase/database'


export const Getfromcollection = async (email, platform) => {
    try {
        let file = [{
            value: 'NONE',
            label: 'NONE',
        }]
        const q = await query(collection(firestore, 'config', email, platform))
        const snapshot = await getDocs(q)

        const data = snapshot.docs.map((doc) => ({
            ...doc.data(), id: doc.id
        }))
        data.forEach(item => {
            file.push({
                value: item.id,
                label: item.id,
            })
        })
        // Snapshot(collection(firestore, "colors"), (snapshot) =>
        //     snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        return [file, data];
    } catch (e) {
        console.log(e)
        toast.error(e.messages)
    }
    return [false, false];
}

export const Getfrombase = async (email, platform) => {
    try {
        let file = [{
            value: 'NONE',
            label: 'NONE',
        }]
        let configs = []
        const query = await ref(fdatabase, `config/${email.split("@")[0]}/${platform}`);
        await onValue(query, (snapshot) => {
            if (!snapshot.exists()){
                return [false, false];
            }
            const data = snapshot.val();

            if (!data){
                return [false, false];
            }
            Object.keys(data).forEach(item => {
                file.push({
                    value: item.toString(),
                    label: item.toString(),
                })
                configs.push(data[item])
            })
            // data.forEach(x => {
            //     console.log(x)
            // })
            // console.log(configs)


        })
        return [file, configs];
    } catch (e) {
        console.log(e)
        toast.error(e.messages)
    }
    return [false, false];
}

// export const create_collection = (email, platform, filename, dicdata) =>{
//     const getData = async () => {
//         // const dbRef = await collection(firestore, 'config')
//         try{
//             const myDocRef = await doc(firestore, 'config', email, platform, filename)
//             await setDoc(myDocRef, dicdata);
//             toast.success('Config Uploaded')
//         }catch (e){
//             console.log(e)
//             toast.error(e)
//         }
//     }
//     getData()
// }

export const create_db = (email, platform, filename, dicdata) =>{
    const getData = async () => {
        // const dbRef = await collection(firestore, 'config')
        try{
            await set(ref(fdatabase, `config/${email.split("@")[0]}/${platform}/${filename}`), dicdata)
            toast.success('Config Uploaded')
        }catch (e){
            console.log(e)
            toast.error(e.message)
        }
    }
    getData()
}

export const delete_collection = (email, platform, filename) =>{
    const getData = async () => {
        // const dbRef = await collection(firestore, 'config')
        try{
            await doc(firestore, 'config', email, platform, filename)
            await deleteDoc(await doc(firestore, 'config', email, platform, filename))
            toast.success('Config Deleted')
        }catch (e){
            console.log(e)
            toast.error(e)
        }
    }
    getData()
}

export const delete_db = (email, platform, filename) =>{
    const getData = async () => {
        // const dbRef = await collection(firestore, 'config')
        try{
            await remove(ref(fdatabase, `config/${email.split("@")[0]}/${platform}/${filename}`))
            toast.success('Config Deleted')
        }catch (e){
            console.log(e)
            toast.error(e)
        }
    }
    getData()
}
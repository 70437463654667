import {Box, Button, CircularProgress, TextField, Typography, useTheme} from "@mui/material";
import {Formik, useFormikContext} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../Components/Header";
import {useEffect, useState} from "react";
import {tokens} from "../../theme";
import {handleLogout, platform, s3} from "../../constant";
import {storage} from "../../App";
import {
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";
import {storagez} from "../../appconfig";
import {Permission, Role} from "appwrite";
import {toast} from "react-toastify";
// import {accountx, storagez} from "../../appconfig";
// import {Permission, Role} from "appwrite";
// import { v4 } from "uuid";

const Formx = () => {
    let aws = false;
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [imgUrl, setImgUrl] = useState(null);
    const [progresspercent, setProgresspercent] = useState(0);
    const [progresspercent2, setProgresspercent2] = useState(0);
    const [progresspercent3, setProgresspercent3] = useState(0);
    let proxy_xx = false;

    let email_ = sessionStorage.getItem('Email')
    let email_x;
    if (email_) {
        email_x = email_;
    }
    if (!email_) {
        handleLogout()
        return;
    }
    let appwr = true;

    const handleFormSubmit = (values) => {
        if (aws){
            handleFormSubmitaws(values)
            return;
        }

        const file = values.file
        const filex = values.songurl
        if (file == null) {
            alert('ERROR PLEASE ADD EMAIL AND PASS FILES')
            return;
        }

        if (filex == null) {
            alert("ERROR PLEASE ADD SONG URL FILES")
            return;
        }

        const imageRef = ref(storage, `${email_x}/${values.whichplatform}/userdata/${file.name}`);
        const imageRef2 = ref(storage, `${email_x}/${values.whichplatform}/links/${filex.name}`);

        const uploadTask = uploadBytesResumable(imageRef, file);
        const uploadTask2 = uploadBytesResumable(imageRef2, filex);
        uploadTask.on("state_changed",
            (snapshot) => {
                const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);
            },
            (error) => {
                alert(error);
            },
            () => {
                uploadTask2.on("state_changed",
                    (snapshot) => {
                        const progress2 =
                            Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setProgresspercent2(progress2);
                    },
                    (error) => {
                        alert(error);
                    },
                    () => {
                        if (values.wanttouploadproxy) {
                            const uploadTask3 = uploadBytesResumable(ref(storage,
                                `${email_x}/${values.whichplatform}/proxy/${values.proxyfile.name}`), values.proxyfile);
                            uploadTask3.on("state_changed",
                                (snapshot) => {
                                    const progress3 =
                                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                                    setProgresspercent3(progress3);
                                },
                                (error) => {
                                    alert(error);
                                },
                                () => {
                                }
                            );
                        }
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            setImgUrl(downloadURL)
                            alert("UPLOADED!!! XOXOXO");
                        });
                    }
                );
            }
        );
    };

    const handleFormSubmitaws = (values) => {
        const file = values.file
        const filex = values.songurl
        (async ()=>{
            if (file == null) {
                alert('ERROR PLEASE ADD EMAIL AND PASS FILES ||' +
                    'PRESS OKAY TO CHECK FOR ANOTHER FILE')
            } else {
                await s3.upload({
                    Bucket: "zeroonzeroapp",
                    Key: `${email_x}/${values.whichplatform}/userdata/${file.name}`,
                    Body: file
                }).on('httpUploadProgress', (evt) => {
                    setProgresspercent(Math.round((evt.loaded / evt.total) * 100))
                })
                    .send((err) => {
                        if (err) alert(err)
                    })

            }

            if (filex == null) {
                alert('ERROR PLEASE ADD EMAIL AND PASS FILES ||' +
                    'PRESS OKAY TO CHECK FOR ANOTHER FILE')
            } else {
                await s3.upload({
                    Bucket: "zeroonzeroapp",
                    Key: `${email_x}/${values.whichplatform}/links/${filex.name}`,
                    Body: filex
                })
                    .on('httpUploadProgress', (evt) => {
                        setProgresspercent2(Math.round((evt.loaded / evt.total) * 100))
                    })
                    .send((err) => {
                        if (err) alert(err)
                    })
            }

            if (!values.wanttouploadproxy || values.proxyfile == null) {
                alert("ERROR PLEASE ADD PROXY URL FILES")
                return;
            } else {
                await s3.upload({
                    Bucket: "zeroonzeroapp",
                    Key: `${email_x}/${values.whichplatform}/proxy/${values.proxyfile.name}`,
                    Body: values.proxyfile
                }).on('httpUploadProgress', (evt) => {
                    setProgresspercent3(Math.round((evt.loaded / evt.total) * 100))
                })
                    .send((err) => {
                        if (err) alert(err)
                    })
            }
            setImgUrl(true);
            alert("UPLOADED!!! XOXOXO");

        })()


        // const paramsx = {
        //     Bucket: "zeroonzeroapp",
        //     Delimiter: '/',
        //     MaxKeys: 1000,
        //     Prefix: `${email_x}/${values.whichplatform}/`,
        // };
        //
        // const objectsStream = await s3.listObjects(paramsx).promise();
        // for (let index = 0; index < objectsStream['Contents'].length; index++) {
        //     console.log(objectsStream['Contents'][index]['Key'])
        //     console.log('DaN')
        // }



    };

    const handleFormSubmitapp = (values) => {
        (async () => {
            if (values.file) {
                try{
                    await storagez.createFile(`${email_.split("@")[0]}_userdata`,
                        `${values.whichplatform.slice(0, 2)}_${values.file.name}`
                        , values.file, [Permission.read(Role.users()), Permission.write(Role.users()),
                            Permission.delete(Role.users()),
                            Permission.update(Role.users())],
                        (snapshot) => {
                            setProgresspercent(snapshot.progress);
                        })

                }catch (e) {
                    console.log(e)
                    toast.error(`EMAILFILE: ${e}`)
                }
            }
            if (values.songurl) {
                try{
                    await storagez.createFile(`${email_.split("@")[0]}_links`,
                        `${values.whichplatform.slice(0, 2)}_${values.songurl.name}`
                        , values.songurl, [Permission.read(Role.users()), Permission.write(Role.users()),
                            Permission.delete(Role.users()),
                            Permission.update(Role.users())],
                        (snapshot) => {
                            setProgresspercent2(snapshot.progress);
                        })

                }catch (e) {
                    console.log(e)
                    toast.error(`LINKFILE: ${e}`)
                }
            }

            if (values.wanttouploadproxy && values.proxyfile){
                try{
                    await storagez.createFile(`${email_.split("@")[0]}_proxy`,
                        `${values.whichplatform.slice(0, 2)}_${values.proxyfile.name}`
                        , values.proxyfile, [Permission.read(Role.users()), Permission.write(Role.users()),
                            Permission.delete(Role.users()),
                            Permission.update(Role.users())],
                        (snapshot) => {
                            setProgresspercent3(snapshot.progress);
                        })

                }catch (e) {
                    console.log(e)
                    toast.error(`PROXYFILE: ${e}`)
                }
            }
            setImgUrl(true);
            toast.success("UPLOADED!!! XOXOXO");
        })()
    };

    const Vaho = () => {
        const {values} = useFormikContext();
        useEffect(() => {
            proxy_xx = values.wanttouploadproxy;
            initialValues.whichplatform = values.whichplatform;
        }, [values]);
        return null;
    };

    return (
        <Box m="20px">
            <Header title="_UPLOAD FILES_" subtitle="UPLOAD ALL NEEDED FILES"/>

            <Formik
                onSubmit={handleFormSubmitapp}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                            }}
                        >
                            <TextField
                                select
                                label="which_platform"
                                onBlur={handleBlur}
                                value={values.whichplatform}
                                onChange={handleChange}
                                name="whichplatform"
                                error={!!touched.whichplatform && !!errors.whichplatform}
                                helperText={touched.whichplatform && errors.whichplatform}
                                sx={{gridColumn: "span 1"}}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <Vaho/>
                                {platform.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <Typography variant="h5" color={colors.greenAccent[500]} sx={{gridColumn: "span 4"}}>
                                EMAIL AND PASS FILE (EMAIL:PASS ONLY)
                            </Typography>
                            <TextField
                                type="file"
                                label="Email and Pass"
                                onBlur={handleBlur}
                                onChange={(event) => {
                                    setFieldValue("file", event.currentTarget.files[0])
                                }}
                                // value={values.userdata}
                                name="userdata"
                                error={!!touched.userdata && !!errors.userdata}
                                helperText={touched.userdata && errors.userdata}
                                sx={{gridColumn: "span 2"}}
                            />
                            <Typography variant="h5" color={colors.greenAccent[500]} sx={{gridColumn: "span 4"}}>
                                SONG LINKS (Album, Playlist ONLY)
                            </Typography>
                            <TextField
                                type="file"
                                label="SONG URL's"
                                onBlur={handleBlur}
                                onChange={(event) => {
                                    setFieldValue("songurl", event.currentTarget.files[0])
                                }}
                                // value={values.songurl}
                                name="songurl"
                                error={!!touched.songurl && !!errors.songurl}
                                helperText={touched.songurl && errors.songurl}
                                sx={{gridColumn: "span 2"}}
                            />
                            <Typography variant="h2" color={colors.redAccent[500]} sx={{gridColumn: "span 2"}}>
                            </Typography>
                            <Typography variant="h5" color={colors.greenAccent[500]} sx={{gridColumn: "span 4"}}>
                                DO YOU WANT TO UPLOAD PROXY FILE TOO?
                            </Typography>
                            <TextField
                                type="checkbox"
                                label="Want To Upload Proxy's"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.wanttouploadproxy}
                                name="wanttouploadproxy"
                                error={!!touched.wanttouploadproxy && !!errors.wanttouploadproxy}
                                helperText={touched.wanttouploadproxy && errors.wanttouploadproxy}
                                sx={{gridColumn: "span 1"}}>
                                <Vaho/>
                            </TextField>
                            <Typography variant="h2" color={colors.redAccent[500]} sx={{gridColumn: "span 3"}}>
                            </Typography>
                            {
                                proxy_xx &&
                                <>
                                    <Typography variant="h5" color={colors.greenAccent[500]}
                                                sx={{gridColumn: "span 4"}}>
                                        PROXY FILE (IP:PORT OR IP:PORT:USER:PASS)
                                    </Typography>
                                    <TextField
                                        type="file"
                                        label="PROXY FILE"
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            setFieldValue("proxyfile", event.currentTarget.files[0])
                                        }}
                                        // value={values.songurl}
                                        name="proxyfile"
                                        error={!!touched.proxyfile && !!errors.proxyfile}
                                        helperText={touched.proxyfile && errors.proxyfile}
                                        sx={{gridColumn: "span 2"}}
                                    />
                                </>
                            }

                            <Typography variant="h2" color={colors.redAccent[500]} sx={{gridColumn: "span 4"}}>
                                PLEASE MAKE SURE YOU SELECTED RIGHT FILES BEFORE UPLOADING
                                AND ALL FILES SHOULD BE IN .TXT FORMAT
                            </Typography>

                            {imgUrl ?
                                <Typography variant="h2" color={colors.greenAccent[500]}>
                                    DONE !!
                                </Typography>
                                :
                                <Typography variant="h3" color={colors.greenAccent[500]}>
                                    [EMAIL:PASS File]
                                    <CircularProgress variant="determinate" value={progresspercent} />
                                    {/*<div className='outerbar'>*/}
                                    {/*    <div className='innerbar' style={{width: `${progresspercent}%`}}>*/}
                                    {/*        {progresspercent}%*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    [SONG_LINKS File]
                                    <CircularProgress variant="determinate" value={progresspercent2} />
                                    {/*<div className='outerbar'>*/}
                                    {/*    <div className='innerbar' style={{width: `${progresspercent2}%`}}>*/}
                                    {/*        {progresspercent2}%*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {proxy_xx &&
                                        <div className='outerbar'>
                                            [PROXY File]
                                            <CircularProgress variant="determinate" value={progresspercent3} />
                                            {/*<div className='innerbar' style={{width: `${progresspercent3}%`}}>*/}
                                            {/*    {progresspercent3}%*/}
                                            {/*</div>*/}
                                        </div>
                                    }
                                </Typography>
                            }

                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <div>
                                <Button type="submit" color="secondary" variant="contained">
                                    Upload Stuff.
                                </Button>
                            </div>
                        </Box>
                    </form>
                )}
            </Formik>
            {/*<form onSubmit={handleSubmit} className='form'>*/}

            {/*    <input type='file' />*/}
            {/*    <button type='submit'>Upload</button>*/}
            {/*</form>*/}
        </Box>
    );
};


const checkoutSchema = yup.object().shape({
    whichplatform: yup.string().required("required"),
    // userdata: yup.string().file()
});

const initialValues = {
    whichplatform: 'spotify',
    userdata: "",
    songurl: "",
    wanttouploadproxy: "",
    proxyfile: ""
};

export default Formx;

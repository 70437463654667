import {Box, Button, TextField, Typography, useTheme} from "@mui/material";
import {Formik, useFormikContext} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../Components/Header";
import {useEffect, useState} from "react";
import {tokens} from "../../theme";
import {handleLogout, platform} from "../../constant";
import {Getfrombase} from "../../firestoremanagement";
import {DataGrid} from "@mui/x-data-grid";
import {toast} from "react-toastify";
import {get_ips, server_command} from "../../appconfig";

let temp_config = '';
let config_ = [{value: 'NONE', label: 'NONE',}]
let server = [{
    value: 'NONE',
    label: 'NONE',
}];

let module = [{
    value: 'app',
    label: 'DESKTOP_APP',},
    {
        value: 'browser',
        label: 'CHROME_BRAVE_BROWSER',},
    {
        value: 'api',
        label: 'API',}];
let red = [{
    value: 'false',
    label: 'No'}, {
    value: 'true',
    label: 'Yes'}];

let action = [{
    value: 'start',
    label: 'START',}, {value: 'stop', label: 'STOP'}];


const Smanger = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    let [ter, terr] = useState(() => {
        return false;
    })

    let [configtoshow, configtoshowset] = useState(() => {
        return [];
    })
    const [loading, setLoading] = useState(() => {
        return false
    });



    let whichto = false;

    let email_ = sessionStorage.getItem('Email')
    let email_x;
    if (email_) {
        email_x = email_;
    }

    if (!email_) {
        handleLogout()
        return;
    }

    // // let temp_config = Getfromcollection(email_, initialValues.whichplatform)

    async function ptemp (){
        temp_config = await Getfrombase(email_x, initialValues.whichplatform)
        if(temp_config[0]){
            config_ = temp_config[0]
            whichto = temp_config[1]
        }
    }

    ptemp().then(r => r)

    // get_stats(email_).then(r => console.log(r))



    get_ips(email_x).then(r => {
        server = r;
        // server.push({
        //     value: '127.0.0.1',
        //     label: '127.0.0.1',
        // })
    }).catch(e => toast.error(e))


    const handleFormSubmit = (values) => {
        setLoading(true);
        const platform = values.whichplatform
        const config = values.config
        const server = values.server
        const red = values.red
        const action = values.action
        if (platform === null && config === "NONE" && server === "NONE") {
            toast.error('ERROR PLEASE CHECk ALL INPUTS')
            return;
        }

        // if (server === null && action === null) {
        //     toast.error('ERROR PLEASE CHECk ALL INPUTS')
        //     return;
        // }
        server_command(server, action, platform, config, red, values.module).then(e => {
            if(e){
                toast.success('COMMAND HAS BEEN SEND TO SERVER')
                return;
            }
            toast.error('ERROR TO SEND COMMAND (RETRY)')
        }).catch(e => { console.log(e);
            toast.error('ERROR TO SEND COMMAND')})

    };

    const Vaho = () => {
        const {values} = useFormikContext();
        useEffect( () => {
            const prase = async () => {
                if (initialValues.whichplatform !== values.whichplatform) {
                    temp_config = await Getfrombase(email_, values.whichplatform)

                    if (temp_config[0]) {
                        config_ = temp_config[0]
                        whichto = temp_config[1]

                    } else {
                        config_ = [{value: 'NONE', label: 'NONE',}]
                        whichto = false;
                    }
                }

                if (values.config !== "NONE" && whichto){
                    whichto.forEach(fg =>{

                        if (fg.configname === values.config){
                            //showconfig = `${JSON.stringify([fg])}`;
                            // convertedObject = [Object.keys(fg), Object.values(fg)];
                            let tempc = []
                            // configtoshowset(tempc);
                            Object.keys(fg).forEach((vl, index) => {
                                tempc.push({
                                    value: fg[vl],
                                    label: vl.toString(),
                                })
                            })

                            configtoshowset(tempc);
                            // configtoshow.map((titleList => console.log(titleList.value, titleList.label)))
                            terr(true)
                        }
                        // else{
                        //     terr(false)
                        // }
                    })
                }

                initialValues.whichplatform = values.whichplatform;
            }
            prase().then()

        }, [values]);
        return null;
    };

    const Cfg = () => {
        return (
            <Box
                m="30px 0 0 0"
                height="40vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}>
                <Typography variant="h5" color={colors.redAccent[500]} sx={{gridColumn: "span 2"}}>
                    CONFIG SNAP !(BEFORE STARTING YOU CAN TAKE A LOOK)
                </Typography>
                <DataGrid pageSize={35}
                          getRowId={(row) =>  row.label}
                          hideFooterPagination rows={configtoshow} columns={columns}/>
            </Box>
        )
    };

    const columns = [
        // {
        //     field: "name",
        //     headerName: "Name",
        //     flex: 1,
        //     cellClassName: "name-column--cell",
        // },
        {
            field: "label",
            headerName: "NAME",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "value",
            headerName: "VALUE",
            flex: 1,
        }
    ];


    return (
        <Box m="30px">
            <Header title="_CONTROL SERVERS_" subtitle="CONTROL ALL YOUR SERVERS FROM HERE "/>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit}>

                        <Box
                            m="50px"
                            display="grid"
                            gap="10px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                            }}
                        >
                            <Typography variant="h5" color={colors.greenAccent[500]} sx={{gridColumn: "span 2"}}>
                                SELECT WHICH PLATFORM ()
                            </Typography>
                            <TextField
                                select
                                label="which_platform"
                                onBlur={handleBlur}
                                value={values.whichplatform}
                                onChange={handleChange}
                                name="whichplatform"
                                error={!!touched.whichplatform && !!errors.whichplatform}
                                helperText={touched.whichplatform && errors.whichplatform}
                                sx={{gridColumn: "span 2"}}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                <Vaho/>
                                {platform.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <Typography variant="h5" color={colors.greenAccent[500]} sx={{gridColumn: "span 2"}}>
                                SELECT SERVER (Wait all your server will extract and show on list)
                            </Typography>
                            <TextField
                                select
                                label="select server"
                                onBlur={handleBlur}
                                value={values.server}
                                onChange={handleChange}
                                name="server"
                                error={!!touched.server && !!errors.server}
                                helperText={touched.server && errors.server}
                                sx={{gridColumn: "span 2"}}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {server.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <Typography variant="h5" color={colors.greenAccent[500]} sx={{gridColumn: "span 2"}}>
                                SELECT CONFIG
                            </Typography>
                            <TextField
                                select
                                label="select config"
                                onBlur={handleBlur}
                                value={values.config}
                                onChange={handleChange}
                                name="config"
                                error={!!touched.config && !!errors.config}
                                helperText={touched.config && errors.config}
                                sx={{gridColumn: "span 2"}}
                                SelectProps={{
                                    native: true,
                                }}>
                                {/*<Cfg />*/}
                                {config_.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <Typography variant="h5" color={colors.greenAccent[500]} sx={{gridColumn: "span 2"}}>
                                SELECT ACTION
                            </Typography>
                            <TextField
                                select
                                label="select action"
                                onBlur={handleBlur}
                                value={values.action}
                                onChange={handleChange}
                                name="action"
                                error={!!touched.action && !!errors.action}
                                helperText={touched.action && errors.action}
                                sx={{gridColumn: "span 2"}}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {action.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <Typography variant="h5" color={colors.greenAccent[500]} sx={{gridColumn: "span 2"}}>
                                RE DOWNLOAD ALL THE FILES IN SERVER
                                ELSE BOT WILL IGNORE DOWNLOADING
                                (FILES = links, proxy, email:pass)
                            </Typography>
                            <TextField
                                select
                                label="re download"
                                onBlur={handleBlur}
                                value={values.red}
                                onChange={handleChange}
                                name="red"
                                error={!!touched.red && !!errors.red}
                                helperText={touched.red && errors.red}
                                sx={{gridColumn: "span 2"}}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {red.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <Typography variant="h5" color={colors.greenAccent[500]} sx={{gridColumn: "span 2"}}>
                                WHICH MODULE YOU WANT TO RUN
                                (API - ONLY AVAILABLE ON ADMIN RDP SO IT WON'T RUN ON YOUR RDP)
                            </Typography>
                            <TextField
                                select
                                label="Module"
                                onBlur={handleBlur}
                                value={values.module}
                                onChange={handleChange}
                                name="module"
                                error={!!touched.module && !!errors.module}
                                helperText={touched.module && errors.module}
                                sx={{gridColumn: "span 2"}}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {module.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>

                        </Box>
                        <Box display="flex" mt="20px">
                            <div>
                                <Button disabled={loading} type="submit" color="secondary" variant="contained">
                                    EXECUTE.
                                </Button>
                            </div>
                        </Box>
                        {ter &&
                            <>
                                <Cfg/>
                                {/*{configtoshow.map((titleList => console.log(titleList.value, titleList.label)))}*/}
                            </>}

                    </form>
                )}
            </Formik>

            {/*{ter &&*/}
            {/*    <>*/}
            {/*        <Cfg/>*/}
            {/*        /!*{configtoshow.map((titleList => console.log(titleList.value, titleList.label)))}*!/*/}
            {/*    </>}*/}
        </Box>
    );
};


const checkoutSchema = yup.object().shape({
    whichplatform: yup.string().required("required"),

    // userdata: yup.string().file()
});

const initialValues = {
    whichplatform: 'spotify',
    server: "NONE",
    config: "NONE",
    action: "start",
    red: 'false',
    module: 'browser'
};

export default Smanger;

import {Account, Client, Databases, Query, Storage} from 'appwrite';
// import {useDirectus} from 'react-directus';
import axios from "axios";


const express_ip = process.env.REACT_APP_E
const client = new Client();


client
    .setEndpoint(process.env.REACT_APP_LINKSERVER)
    .setProject(process.env.REACT_APP_PID);

export const accountx = new Account(client);
export const storagez = new Storage(client);
export const database = new Databases(client);

//Database

// const upload = async (values) => {
//     const directus = new useDirectus('https://pyeigmll.directus.app');
//     // const directus = new Directus('https://pyeigmll.directus.app', {
//     //     auth: {
//     //         staticToken: '_ULcp62i-mTeFQSxwxAZRMXkd5n5cxK',
//     //     },
//     // });
//     // const directus = new DirectusSDK('https://pyeigmll.directus.app');
//
//     const email = 'malvo01;
//     const password = 'malvo01;
//     await directus.auth
//         .login({email, password})
//         .then(async () => {
//             await directus.auth.refresh()
//             const form = new FormData();
//             form.append(values.file.name, values.file);
//             const fileId = await directus.files.createOne(form)
//         })
//         .catch((error) => {
//             console.log(error.messages)
//         });
// }
// export const make_bucket = async (email_, type) => {
//     await axios
//         .get(`http://localhost:7070/api/v1/createBucket/${email_}/${type}`)
//         .then((d) => {
//             return [true, d]
//         })
//         .catch((err) => {
//             return [false, err]
//         });
// }
// backendapi
export const total_streams = async (email_) => {
    let rt;
    let data;
    try{
        data = await axios.get(`${express_ip}/api/v1/counterread/${email_}`)
        rt = true;
        data = JSON.parse(data.data)
        data = data.totalcounts
    }
    catch (e){
        console.log(e)
        data = e
        rt = false;
    }

    return [rt, data]
}

export const verify = async (email_) => {
    let rt;
    let data;
    try{
        data = await axios.get(`${express_ip}/api/v1/verify/${email_}`)
        rt = true;
    }
    catch (e){
        data = e
        rt = false;
    }

    return [rt, data]
}

export const make_bucket_aio = async (email_) => {
    let rt = true;
    let data = true;
    try{
        await axios.get(`${express_ip}/api/v1/createBucket/${email_}/userdata`)
        await axios.get(`${express_ip}/api/v1/createBucket/${email_}/proxy`)
        await axios.get(`${express_ip}/api/v1/createBucket/${email_}/links`)
    }catch (e) {
        rt = false
        data = e
    }

    return [rt, data]
}

export const get_file_list = async (email_, type, platform) => {
    let data = true;
    let df = [{
        value: 'NONE',
        label: 'NONE',
    }]
    if (type === 'proxy'){
        df = [{
            value: 'NONE',
            label: 'Default By 010',
        },
            {
                value: 'BIND',
                label: 'BIND WITH ACCOUNT',
            }]
    }
    try{
        data = await storagez.listFiles(`${email_.split('@')[0]}_${type}`)
        await data.files.forEach(item =>{
            if(item.$id.split("_")[0] === platform.slice(0, 2)){
                df.push({
                    value: item.name.toString(),
                    label: item.name.toString(),
                })
            }
        })
    }catch (e) {
        console.log(e)
    }

    return df
}

export const delete_file = async (email_, type, platform, file) => {
    let re = true;
    try{
        await storagez.deleteFile(`${email_.split('@')[0]}_${type}`,
            `${platform.slice(0, 2)}_${file}`)
    }catch (e) {
        console.log(e)
        re = false;
    }
    return re
}

// backend
export const get_ips = async (email_) => {
    let df = [{
        value: 'NONE',
        label: 'NONE',
    }]
    try{
        const lists = await database.listDocuments('servers',
            `${email_.split('@')[0]}`, [Query.limit(100)])

        lists.documents.forEach(item =>{
            df.push({
                value: item.ip.toString(),
                label: item.ip.toString(),
            })
        })

        return df;
    }catch (e) {
        console.log(e)
    }
    return df;
}

export const delete_ip = async (email_, ip) => {
    try{
        try{
            await database.deleteDocument('stats',
                `${email_.split('@')[0]}`, ip)
        }catch (e){}
        return await database.deleteDocument('servers',
            `${email_.split('@')[0]}`, ip)
    }catch (e) {
        return e
    }
}

export const get_stats = async (email_) => {
    let df = []
    try{
        const lists = await database.listDocuments('stats',
            `${email_.split('@')[0]}`, [Query.limit(100)])

        lists.documents.forEach(item => {
            df.push({
                item,
            })
        })

        return df;
    }catch (e) {
        console.log(e)
    }
    return df;
}

export const stats_date = async (email_, platform, date) => {
    try{
        let total_data = "";
        let data = await database.listDocuments(email_.split('@')[0], date+platform,
            [Query.limit(100)])
        total_data = data

        if (data.total > 100){
            while (true){
                try{
                    const lastId = data.documents[data.documents.length - 1].$id;
                    data = await database.listDocuments(email_.split('@')[0], date+platform,
                        [Query.limit(100), Query.cursorAfter(lastId)])
                    if (data.documents.length === 0) break;
                    data.documents.forEach(d => {
                        total_data.documents.push(d)
                    })
                }catch (e) {break}
            }



        }

        return total_data
        // rt = true;
        // data = JSON.parse(data.data)
        // data = data.totalcounts
    }
    catch (e){
        // console.log(e)
    }

    return false
}
// direct server
function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const server_command = async (ip, action, platform, config, red, module, pre) => {
    let rt = true;

    let i = 1, n = 3;

    while (i <= n) {
        i++
        try{
            const data = {'action': action, 'platform': platform, 'config': config, 'red': red, 'module': module,
                'pre': pre}
            // await axios.post(`http://${ip}:8080`, JSON.stringify(data))
            await axios.post(`${express_ip}/api/v1/forward/${ip}/`, {"data": data}, {timeout: 5000})
            return true
        }
        catch (e){
            return false
        }
        await sleep(3000)
    }


    return false
}

export const server_check = async (ip) => {

    try{
        const data = {'data': {'action': 'active'}}
        // return await axios.post(`http://${ip}:8080`, JSON.stringify(data), {timeout: 25})
        return await axios.post(`${express_ip}/api/v1/forward/${ip}/`, data)
    }
    catch (e){
        console.log(e)
        return false;
    }

}

export const server_restart = async (ip, fg) => {
    try{
        let data = {'action': 're_server'}
        if(!fg){
            data = {'action': 're_bot'}
        }
        await axios.post(`${express_ip}/api/v1/forward/${ip}/`, {"data": data})
        return true;
        // return await axios.post(`http://${ip}:8080`, JSON.stringify(data), {timeout: 4})
    }
    catch (e){
    }

    return false;
}

export const server_ss = async (ip) => {
    try{
        return (await axios.get(`${express_ip}/api/v1/forward2/${ip}/`)).data
        // let re = await axios.post(`http://${ip}:8080`, JSON.stringify(data), {timeout: 30})
        // console.log(re.status)
        // return re
    }
    catch (e){
    }

    return false;
}

export const server_sss = async (ip) => {
    try{

        return (await axios.post(`http://192.168.1.10:65534`, JSON.stringify({'action': 'screenshot'}),
            {insecureHTTPParser: true})).data
        // let re = await axios.post(`http://${ip}:8080`, JSON.stringify(data), {timeout: 30})
        // console.log(re.status)
        // return re
    }
    catch (e){
    }
    return false;
}
import * as React from 'react';
import Button from '@mui/material/Button';
import LoginIcon from "@mui/icons-material/Login";
import {tokens} from "../../theme";
import {useTheme} from "@mui/material";


export default function BasicButtons() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // onClick={handleAction}
    return (
        <Button
            type="submit"
            fullWidth
            sx={{ mt: 3, mb: 2 }}
            variant="contained" startIcon={<LoginIcon sx={{ color: colors.greenAccent[600], fontSize: "35px" }}/>}>Login</Button>
    );
}

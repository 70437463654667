import {Box, Button, TextField, Typography, useTheme} from "@mui/material";
import {Formik, useFormikContext} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../Components/Header";
import {useEffect, useState} from "react";
import {captcha_, currencies, handleLogout, platform, proxyType, s3} from "../../constant";
import {tokens} from "../../theme";
import {create_db, Getfrombase} from "../../firestoremanagement";
import {toast} from "react-toastify";
import {get_file_list} from "../../appconfig";
import Checkbox from "@mui/material/Checkbox";

let email_x;
let Userdata_ = [{
  value: 'NONE',
  label: 'NONE',
}];
let Links = [{
  value: 'NONE',
  label: 'NONE',
}];

let proxy_ = [{
  value: 'NONE',
  label: 'Default By 010',
},
  {
    value: 'BIND',
    label: 'BIND WITH ACCOUNT',
  }];

let apimodex = [{
  value: true,
  label: 'True',
},
  {
    value: false,
    label: 'False',
  }];
let config = [{
  value: 'NONE',
  label: 'NONE',}];
let tempcon = 'NONE';

const Configedit = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [newcon, setnewcon] = useState(re =>{
    return false;
  });

  const handleFormSubmit = (values) => {
    values.user_email = email_x
    values.id = values.config
    // localStorage.setItem(values.configname, JSON.stringify(values));
    // try{
    //   if (localStorage.getItem(values.configname)){
    //     localStorage.getItem(values.configname)
    //   }
    // }catch (e) {}
    if (values.userdata === "NONE"){
      toast.error('email:pass cant be empty')
      return
    }
    if (values.songurl === "NONE"){
      toast.error('songlinks cant be empty')
      return
    }
    if (values.config === "NONE" || values.configname === "NONE"){
      toast.error('config cant be empty')
      return
    }

    if (values.whichplatform === "deezer" || values.whichplatform === 'amzmusic'
        || values.whichplatform === 'tidal'){
      if (values.APIKEY === "key" || values.APIKEY === ""){
        toast.error('CAP API KEY cant be empty')
        return
      }
    }

    try{
      let con_z = values.config
      if (values.newconfig){
        con_z = values.configname
        values.config = con_z
        values.id = con_z
      }
      create_db(email_, values.whichplatform, con_z, values)
    }catch (e) {
      toast.error(e.message)
    }

  };

  let email_ = sessionStorage.getItem('Email')

  if (email_) {
    email_x = email_;
  }
  if (!email_) {
    handleLogout()
    return ;
  }

  let captcha = false;
  let capip = false;
  let temvalue = initialValues.whichplatform;



  let aws = false;


  if (!aws) {
    get_file_list(email_x, 'userdata', initialValues.whichplatform).then(
        e => {
          Userdata_ = e
        })
    get_file_list(email_x, 'links', initialValues.whichplatform).then(
        e => {
          Links = e
        })
    get_file_list(email_x, 'proxy', initialValues.whichplatform).then(
        e => {
          proxy_ = e
        })
  }
  // async function fetchData() {
  //   await sleep(3000);
  // }
  let whichto = false;

  const AutoSubmitToken = () => {
    // Grab values and submitForm from context
    let { values } = useFormikContext();

    useEffect(() => {
      if (values.whichplatform === 'deezer' || values.whichplatform === 'amzmusic' ||
          values.whichplatform === 'tidal') {
        captcha = true;
      }else {
        captcha = false;
      }
      if (values.CAP !== '3') {
        capip = false;
      }else{
        capip = true;
      }
      if (values.PPA_MAX <= values.PPA_MIN){
        values.PPA_MAX = values.PPA_MIN + 1
      }
      if (values.PPL_MAX <= values.PPL_MIN){
        values.PPL_MAX = values.PPL_MIN + 1
      }
      if (values.PLAY_MIN === 0){
        values.PLAY_MAX = values.PLAY_MIN = 0
      }else{
        if (values.PLAY_MAX <= values.PLAY_MIN){
          values.PLAY_MAX = values.PLAY_MIN + 5
        }
      }

      if (temvalue !== values.whichplatform){
        Userdata_ = [{
          value: 'NONE',
          label: 'NONE',
        }];

        Links = [{
          value: 'NONE',
          label: 'NONE',
        }];
        proxy_ = [{
          value: 'NONE',
          label: 'Default By 010',
        },
          {
            value: 'BIND',
            label: 'BIND WITH ACCOUNT',
          }]
        Getfrombase(email_, values.whichplatform).then(temp_config =>{
          if (temp_config[0]) {
            config = temp_config[0]
            whichto = temp_config[1]

          } else {
            config = [{
              value: 'NONE',
              label: 'NONE',
            }];
            whichto = false;
          }

        })

        get_file_list(email_x, 'userdata', values.whichplatform).then(
            e => {
              Userdata_ = e
              if (values.whichplatform === 'soundcloud' || values.whichplatform === 'ytmusic' ||
                  values.whichplatform === 'youtube' || values.whichplatform === 'tiktok'  ||
                  values.whichplatform === 'spreaker') {
                Userdata_ = [...Userdata_, {
                  value: false,
                  label: 'ACCOUNT LESS',
                }]
              }
            }).catch(e => {
          console.log(e)
        })
        get_file_list(email_x, 'links', values.whichplatform).then(
            e => {
              Links = e
            }).catch(e => {
          console.log(e)
        })
        get_file_list(email_x, 'proxy', values.whichplatform).then(
            e => {
              proxy_ = e
            }).catch(e => {
          console.log(e)
        })
      }

      console.log(tempcon)
      if (values.config !== "NONE" && values.config !== tempcon){

        if (whichto){
          whichto.forEach(fg =>{
            if (fg.configname === values.config){
              Object.keys(fg).forEach((vl, index) => {
                values[vl] = fg[vl]
              })

            }
          })
        }else{
          Userdata_ = [{
            value: 'NONE',
            label: 'NONE',
          }];
          Getfrombase(email_, values.whichplatform).then(temp_config =>{
            if (temp_config[0]) {
              config = temp_config[0]
              whichto = temp_config[1]
              whichto.forEach(fg =>{
                if (fg.configname === values.config){
                  Object.keys(fg).forEach((vl, index) => {
                    values[vl] = fg[vl]})}})
            }
            else {
              config = [{
                value: 'NONE',
                label: 'NONE',
              }];
              whichto = false;
            }

          })

        }

      }

      tempcon = values.config

      initialValues.whichplatform = temvalue = values.whichplatform;

    }, [values]);
    return null;
  };

  return (
    <Box m="20px">
      <Header title="_CONFIG_EDITOR_" subtitle="Edit Or Make A New CONFIG Out OF IT" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
            setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                  select
                  label="which_platform"
                  onBlur={handleBlur}
                  value={values.whichplatform}
                  onChange={handleChange}
                  name="whichplatform"
                  error={!!touched.whichplatform && !!errors.whichplatform}
                  helperText={touched.whichplatform && errors.whichplatform}
                  sx={{ gridColumn: "span 2" }}
                  SelectProps={{
                    native: true,
                  }}
              >
                <AutoSubmitToken />
                {platform.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}
              </TextField>
              <TextField
                  select
                  label="Config"
                  onBlur={handleBlur}
                  onChange={ee =>{

                    setFieldValue("config", ee.currentTarget.value)
                  }}
                  value={values.config}
                  name="config"
                  error={!!touched.config && !!errors.config}
                  helperText={touched.config && errors.config}
                  sx={{gridColumn: "span 2"}}
                  SelectProps={{
                    native: true,
                  }}
              >
                {config.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}
              </TextField>
              <TextField
                  select
                  label="Email and Pass"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.userdata}
                  name="userdata"
                  error={!!touched.userdata && !!errors.userdata}
                  helperText={touched.userdata && errors.userdata}
                  sx={{ gridColumn: "span 1" }}
                  SelectProps={{
                    native: true,
                  }}
              >
                {Userdata_.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
              ))}
              </TextField>
              <TextField
                  select
                  label="Song Urls"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.songurl}
                  name="songurl"
                  error={!!touched.songurl && !!errors.songurl}
                  helperText={touched.songurl && errors.songurl}
                  sx={{ gridColumn: "span 1" }}
                  SelectProps={{
                    native: true,
                  }}
              >
                {Links.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}
              </TextField>
              <TextField
                  select
                  label="Proxy"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Proxy}
                  name="Proxy"
                  error={!!touched.Proxy && !!errors.Proxy}
                  helperText={touched.Proxy && errors.Proxy}
                  sx={{ gridColumn: "span 1" }}
                  SelectProps={{
                    native: true,
                  }}
              >
                {proxy_.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}
              </TextField>

              <TextField
                  select
                  label="Proxy Type"
                  onBlur={handleBlur}
                  value={values.proxyType}
                  onChange={handleChange}
                  name="proxyType"
                  error={!!touched.proxyType && !!errors.proxyType}
                  helperText={touched.proxyType && errors.proxyType}
                  sx={{ gridColumn: "span 1" }}
                  SelectProps={{
                    native: true,
                  }}
              >
                {proxyType.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}
              </TextField>

              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="webhook_url"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.webhook_url}
                name="webhook_url"
                error={!!touched.webhook_url && !!errors.webhook_url}
                helperText={touched.webhook_url && errors.webhook_url}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="threads"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.threads}
                name="threads"
                InputProps={{ inputProps: { min: "1", max: "2000", step: "1" } }}
                error={!!touched.threads && !!errors.threads}
                helperText={touched.threads && errors.threads}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="PLAY_MIN"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.PLAY_MIN}
                  name="PLAY_MIN"
                  error={!!touched.PLAY_MIN && !!errors.PLAY_MIN}
                  helperText={touched.PLAY_MIN && errors.PLAY_MIN}
                  InputProps={{ inputProps: { min: "0", max: "2000", step: "1" } }}
                  sx={{ gridColumn: "span 1" }}
              />

              <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="PLAY_MAX"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.PLAY_MAX}
                  name="PLAY_MAX"
                  error={!!touched.PLAY_MAX && !!errors.PLAY_MAX}
                  helperText={touched.PLAY_MAX && errors.PLAY_MAX}
                  InputProps={{ inputProps: { min: "0", max: "2000", step: "1" } }}
                  sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="PPA_MIN"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.PPA_MIN}
                name="PPA_MIN"
                error={!!touched.PPA_MIN && !!errors.PPA_MIN}
                helperText={touched.PPA_MIN && errors.PPA_MIN}
                sx={{ gridColumn: "span 1" }}
              />

              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="PPA_MAX"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.PPA_MAX}
                name="PPA_MAX"
                error={!!touched.PPA_MAX && !!errors.PPA_MAX}
                helperText={touched.PPA_MAX && errors.PPA_MAX}
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="PPL_MIN"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.PPL_MIN}
                  name="PPL_MIN"
                  error={!!touched.PPL_MIN && !!errors.PPL_MIN}
                  helperText={touched.PPL_MIN && errors.PPL_MIN}
                  sx={{ gridColumn: "span 1" }}
              />

              <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="PPL_MAX"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.PPL_MAX}
                  name="PPL_MAX"
                  error={!!touched.PPL_MAX && !!errors.PPL_MAX}
                  helperText={touched.PPL_MAX && errors.PPL_MAX}
                  sx={{ gridColumn: "span 1" }}
              />
              <TextField
                  select
                  label="shuffle_url"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.shuffle_url}
                  name="shuffle_url"
                  error={!!touched.shuffle_url && !!errors.shuffle_url}
                  helperText={touched.shuffle_url && errors.shuffle_url}
                  sx={{ gridColumn: "span 1" }}
                  SelectProps={{
                    native: true,
                  }}
              >
                {currencies.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}
              </TextField>

              <TextField
                  select
                  label="shuffle_songs"
                  onBlur={handleBlur}
                  value={values.shuffle_songs}
                  onChange={handleChange}
                  name="shuffle_songs"
                  error={!!touched.shuffle_songs && !!errors.shuffle_songs}
                  helperText={touched.shuffle_songs && errors.shuffle_songs}
                  sx={{ gridColumn: "span 1" }}
                  SelectProps={{
                    native: true,
                  }}
              >
                {currencies.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}
              </TextField>

              <TextField
                  select
                  label="ReUse Account"
                  onBlur={handleBlur}
                  value={values.ONEGO}
                  onChange={handleChange}
                  name="ONEGO"
                  error={!!touched.ONEGO && !!errors.ONEGO}
                  helperText={touched.ONEGO && errors.ONEGO}
                  sx={{ gridColumn: "span 1" }}
                  SelectProps={{
                    native: true,
                  }}
              >
                {currencies.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}
              </TextField>
              <TextField
                  select
                  label="Api Checker"
                  onBlur={handleBlur}
                  value={values.apichecker}
                  onChange={handleChange}
                  name="apichecker"
                  error={!!touched.apichecker && !!errors.apichecker}
                  helperText={touched.apichecker && errors.apichecker}
                  sx={{ gridColumn: "span 1" }}
                  SelectProps={{
                    native: true,
                  }}
              >
                {currencies.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}
              </TextField>

              <TextField
                  fullWidth
                  type="number"
                  label="song_like_percentage_"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.song_like_percentage_}
                  name="song_like_percentage_"
                  InputProps={{ inputProps: { min: "0", max: "100", step: "1" } }}
                  error={!!touched.song_like_percentage_ && !!errors.song_like_percentage_}
                  helperText={touched.song_like_percentage_ && errors.song_like_percentage_}
                  sx={{ gridColumn: "span 1" }}
              />
              <TextField
                  fullWidth
                  type="number"
                  label="Album_or_Playlist_like_percentage_"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.playlist_like_follow_percentage_}
                  name="playlist_like_follow_percentage_"
                  InputProps={{ inputProps: { min: "0", max: "100", step: "1" } }}
                  error={!!touched.playlist_like_follow_percentage_ && !!errors.playlist_like_follow_percentage_}
                  helperText={touched.playlist_like_follow_percentage_ && errors.playlist_like_follow_percentage_}
                  sx={{ gridColumn: "span 1" }}
              />
              <TextField
                  select
                  label="Force Play"
                  onBlur={handleBlur}
                  value={values.force}
                  onChange={handleChange}
                  name="force"
                  error={!!touched.force && !!errors.force}
                  helperText={touched.force && errors.force}
                  sx={{ gridColumn: "span 1" }}
                  SelectProps={{
                    native: true,
                  }}
              >
                {currencies.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}
              </TextField>
              <TextField
                  select
                  label="API_MODEX"
                  onBlur={handleBlur}
                  value={values.API_MODEX}
                  onChange={handleChange}
                  name="API_MODEX"
                  error={!!touched.API_MODEX && !!errors.API_MODEX}
                  helperText={touched.API_MODEX && errors.API_MODEX}
                  sx={{ gridColumn: "span 1" }}
                  SelectProps={{
                    native: true,
                  }}
              >
                {apimodex.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                ))}
              </TextField>

              {captcha ?
                  <>
                    <TextField
                        select
                        label="captcha Server"
                        onBlur={handleBlur}
                        value={values.CAP}
                        onChange={handleChange}
                        name="CAP"
                        error={!!touched.CAP && !!errors.CAP}
                        helperText={touched.CAP && errors.CAP}
                        sx={{ gridColumn: "span 1" }}
                        SelectProps={{
                          native: true,
                        }}
                    >
                      <AutoSubmitToken />
                      {captcha_.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                      ))}

                    </TextField>
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="CAPTCHA_API_KEY"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.APIKEY}
                        name="APIKEY"
                        error={!!touched.APIKEY && !!errors.APIKEY}
                        helperText={touched.APIKEY && errors.APIKEY}
                        sx={{ gridColumn: "span 1" }}
                    />
                    {capip ?
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            label="CAPTCHA_MONSTER IP"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.IP}
                            name="IP"
                            error={!!touched.IP && !!errors.IP}
                            helperText={touched.IP && errors.IP}
                            sx={{ gridColumn: "span 1" }}
                        />
                        : <></>}
                  </>
                  : <></>}

              <TextField
                  type="checkbox"
                  label="Make New Config From Existing One"
                  onBlur={handleBlur}
                  onChange={ee =>{
                    setFieldValue("newconfig", ee.currentTarget.checked)
                    setnewcon(ee.currentTarget.checked)
                  }}
                  // onChange={handleChange}
                  value={values.newconfig}
                  name="newconfig"
                  error={!!touched.newconfig && !!errors.newconfig}
                  helperText={touched.newconfig && errors.newconfig}
                  SelectProps={{
                    native: true,
                  }}
                  sx={{gridColumn: "span 1",
                    "& label": {
                      "&.Mui-focused": {
                        color: 'green'
                      }
                    }}}>
                InputLabelProps={}
              </TextField>

              {newcon &&
                  <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.configname}
                      name="configname"
                      error={!!touched.configname && !!errors.configname}
                      helperText={touched.configname && errors.configname}
                      sx={{ gridColumn: "span 2" }}
                  />
              }



            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              {newcon ?
                  <Button type="submit" color="secondary" variant="contained">
                Make New Config
              </Button>
                  :
                  <Button type="submit" color="secondary" variant="contained">
                    Update Config
                  </Button>
              }
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

// const phoneRegExp =
//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;


const checkoutSchema = yup.object().shape({

  webhook_url: yup.string(),
  threads: yup.number().required("required").positive().integer(),
  PPA_MIN: yup.number().required("required").positive().integer(),
  PPA_MAX: yup.number().required("required").positive().integer(),
  PPL_MIN: yup.number().required("required").positive().integer(),
  PPL_MAX: yup.number().required("required").positive().integer(),
  PLAY_MIN: yup.number().required("required").integer(),
  PLAY_MAX: yup.number().required("required").integer(),
  shuffle_url: yup.boolean().required("required"),
  shuffle_songs: yup.boolean().required("required"),
  song_like_percentage_: yup.number().required("required").integer(),
  ONEGO: yup.boolean().required("required"),
  apichecker: yup.boolean().required("required"),
  whichplatform: yup.string().required("required"),
  userdata: yup.string().required("required"),
  songurl: yup.string().required("required"),
  // Proxy: yup.string().required("required"),
  proxyType: yup.string().required("required"),
  playlist_like_follow_percentage_: yup.number().required("required").integer(),
  force: yup.boolean().required("required"),
  // userdata: yup.string().file()
});

let initialValues = {
  user_email: email_x,
  id: '',
  configname: "NONE",
  webhook_url: "false",
  threads: 2,
  PPA_MIN: 3,
  PPA_MAX: 5,
  PPL_MIN: 5,
  PPL_MAX: 8,
  PLAY_MIN: 0,
  PLAY_MAX: 0,
  shuffle_url: false,
  shuffle_songs: false,
  song_like_percentage_: 0,
  ONEGO: true,
  apichecker: false,
  whichplatform: 'spotify',
  CAP: 1,
  APIKEY: "key",
  IP: "1.1.1.1",
  playlist_like_follow_percentage_: 0,
  userdata: '',
  songurl: "",
  Proxy: 'NONE',
  force: 'true',
  proxyType: 'http',
  API_MODEX: false,
  config: 'NONE',
  newconfig: '',
};

export default Configedit;

import {Box, Button, Typography, useTheme} from "@mui/material";
import Header from "../../Components/Header";
import {useState} from "react";
import {tokens} from "../../theme";
import {handleLogout} from "../../constant";
import {DataGrid} from "@mui/x-data-grid";
import {toast} from "react-toastify";
import {delete_ip, get_stats, server_restart} from "../../appconfig";


// import { v4 } from "uuid";
let mockDataTeam = [];
function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const Stats = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [loading, setLoading] = useState(() => {
        return false
    });
    const columns = [
        // { field: "id", headerName: "ID" },
        // {
        //     field: "name",
        //     headerName: "Name",
        //     flex: 1,
        //     cellClassName: "name-column--cell",
        // },
        {
            field: "ipaddr",
            headerName: "IP Address",
            flex: 0.4,
            minWidth: 80,
        },
        {
            field: "streams",
            headerName: "Total Streams",
            flex: 0.4,
        },
        {
            field: "slogin",
            headerName: "Success Login",
            flex: 0.3,
        },
        {
            field: "flogin",
            headerName: "Fail Login",
            flex: 0.3,
        },
        {
            field: "cpu",
            headerName: "CPU (%)",
            flex: 0.3,
        },
        {
            field: "ram",
            headerName: "RAM (%)",
            flex: 0.3,
        },
        {
            field: "estimation",
            headerName: "Estimation",
            flex: 0.3,
        },
        {
            field: "running",
            headerName: "Running",
            flex: 0.3,
        },
        {
            field: "threads",
            headerName: "Threads",
            flex: 0.2,
        },
        {
            field: "platform",
            headerName: "Platform",
            flex: 0.4,
        },
        {
            field: "time",
            headerName: "Last Update",
            flex: 0.6,
        }
    ];

    const [select, setSelection] = useState(() => {
        return [{}];
    });

    const [temp, tempset] = useState(() => {
        return [];
    });

    let email_ = sessionStorage.getItem('Email')
    let email_x;

    if (email_) {
        email_x = email_;
    }

    if (!email_) {
        handleLogout()
        return;
    }

    const Ipsd = async () => {
        mockDataTeam = [];

        let server = await get_stats(email_x)

        for (let item of server){
            mockDataTeam.push({
                streams: item.item.streams.toString(),
                ipaddr: item.item.$id.toString(),
                cpu: item.item.cpu.toString()+'%',
                ram: item.item.ram.toString()+'%',
                platform: item.item.platform.toString(),
                slogin: item.item.slogin.toString(),
                flogin: item.item.flogin.toString(),
                estimation: item.item.estimation.toString(),
                running: item.item.running.toString(),
                threads: item.item.threads.toString(),
                time: item.item.$updatedAt.toString().split('.')[0],
            })
        }

        await tempset(mockDataTeam)
    };

    const handleFormSubmit = async (fg) => {
        try {
            setLoading(true);
            for (const data of select) {
                for (let li of mockDataTeam) {
                    if (li.ipaddr === data) {
                        await server_restart(li.ipaddr, fg).then()
                    }
                }
                await sleep(1000)
                // mockDataTeam.forEach((li) => {
                //     if (li.ipaddr === data){
                //         server_restart(li.ipaddr).then()
                //     }
                // })
            }
            toast.success(`WAIT FOR 10 SEC`)
            await sleep(10000)
            toast.success(`RESTARTED LIST`)
            await Ipsd()

            setLoading(false);
        } catch (e) {
            console.log(e)
        }
    };

    const handleFormSubmitre = async () => {
        try {
            for (const data of select) {
                for (let li of mockDataTeam) {
                    if (li.ipaddr === data) {
                        await delete_ip(email_x, li.ipaddr).then()

                    }
                }

            }
            await Ipsd()
            toast.success(`REMOVED LIST`)
            tempset(3)
            await sleep(5000)
            setLoading(false);
        } catch (e) {
            console.log(e)
        }
    };

    return (
        <Box m="20px">
            <Header title="STATS" subtitle="CLICK REFRESH TO EXTRACT ALL OF YOURS STATS" />

            <Box
                m="40px 0 0 0"
                height="60vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}>
                <DataGrid pageSize={100}
                          checkboxSelection
                          getRowHeight={() => 'auto'}
                          rows={temp} columns={columns}
                          getRowId={(row) =>  row.ipaddr}
                          onSelectionModelChange={(newSelection) => {
                              setSelection(newSelection);
                          }}/>

                <Box
                    justifyContent="end" mt="40px" display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: "span 4" },
                    }}
                >
                    <Button disabled={loading} onClick={async () => handleFormSubmit(true)}
                            type="submit" color="secondary"
                            variant="contained">
                        Restart Selected.
                    </Button>
                    <Button disabled={loading} onClick={async () => handleFormSubmit(false)}
                            type="submit" color="warning"
                            variant="contained">
                        Restart Bots in.
                    </Button>

                    <Button onClick={async () => Ipsd()} type="submit" color="info" variant="contained">
                        Refresh Chart.
                    </Button>

                    <Typography variant="h3" color={colors.redAccent[500]} sx={{gridColumn: "span 3"}}>
                        RESTART SERVER OR RUNNING BOTS IN SERVER | REMOVE IP FROM LIST DB | REFRESH LISTS |
                        BOT WILL ONLY RESTART IF THEY WHERE RUNNING IN FIRST PLACE
                    </Typography>
                    <Typography variant="h4" color={colors.redAccent[500]} sx={{gridColumn: "span 4"}}>
                        AFTER CLICKING WAIT FOR PAGE TO REFRESH DON'T SPAM WITH CLICKS
                        {`\n`} WAIT IT LL TAKE TIME TO EXTRACT!!
                    </Typography>

                </Box>

            </Box>

        </Box>
    );
};


export default Stats;

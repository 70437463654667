import {Box, Button, TextField, Typography, useTheme} from "@mui/material";
import Header from "../../Components/Header";
import {useState} from "react";
import {tokens} from "../../theme";
import {handleLogout, platform} from "../../constant";
import {Formik} from "formik";
import { Line } from "react-chartjs-2";
import dayjs from 'dayjs';
import {
    CategoryScale,
    Chart as ChartJS,
    Filler, Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip
} from "chart.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import {stats_date} from "../../appconfig";
import {toast} from "react-toastify";

const Statss = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");


    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
    );

    const options = {
        responsive: true,

        plugins: {
            title: {
                display: true,
                text: 'Stats by per links',
            },
            ticks: {
                beginAtZero: true,
            },
        },
    };

    const [dates, setDates] = useState(() => {
        return {
            labels: [],
            datasets: [],
        };
    });

    let email_ = sessionStorage.getItem('Email')
    let email_x;

    if (email_) {
        email_x = email_;
    }

    if (!email_x) {
        handleLogout()
        return;
    }

    const handleFormSubmit = async (fg) => {
        try {

            // const min_date = dayjs(fg.date).format("DD-MM-YYYY")
            // const max_date = dayjs(fg.date_max).format("DD-MM-YYYY")
            if(!fg.date && !fg.date_max){
                toast.error('PLEASE SET DATE CORRECTLY')
                return;
            }
            //date
            const listDate = [];
            const listDate_new = [];
            let linkset = []
            let total_db = []

            const startDate =fg.date;
            const endDate = fg.date_max;
            const dateMove = new Date(startDate);
            let strDate = startDate;
            if (strDate === endDate){
                listDate.push(dayjs(strDate).format("DD-MM-YYYY"));
            }
            let cnt = 0
            while (strDate < endDate) {
                strDate = dateMove.toISOString().slice(0, 10);
                listDate.push(dayjs(strDate).format("DD-MM-YYYY"));
                // console.log(strDate)
                dateMove.setDate(dateMove.getDate() + 1);
                cnt++
                if (cnt > 7){
                    break
                }
            }

            // console.log(listDate)
            for (let cd of listDate){
                const dfd = await stats_date(email_x, fg.whichplatform, cd)
                if(dfd){
                    listDate_new.push(cd)
                    for (let gg of dfd.documents){
                        if (!linkset.includes(gg.link)) {
                            linkset.push(gg.link)
                            total_db[gg.link] = []
                        }
                        total_db[gg.link].push({'streams': gg.streams, 'date': cd})
                    }

                }
            }
            // console.log(listDate_new)
            // console.log(linkset)
            // console.log(total_db)

            let data_push = {
                labels: listDate_new,
                datasets: [],
            }

            for (let ff of linkset){
                let temp_data = []
                for (let fg of total_db[ff]){
                    listDate_new.forEach((ix, index) => {
                        if (ix === fg.date){
                            temp_data[index]= fg.streams
                        }else{
                            if(!temp_data[index]){
                                temp_data[index] = 0
                            }
                        }
                    })
                }
                let tosli = 1
                try{
                    if (ff.split('/')[ff.split('/').length - 1].length <= 1){
                        tosli = 2
                    }
                }catch (e){

                }

                data_push.datasets.push(
                    {
                        fill: true,
                        label: `${ff.split('/')[ff.split('/').length - tosli]}`,
                        data: temp_data,
                        backgroundColor: [
                            "rgba(255, 99, 132, 0.2)",
                            "rgba(54, 162, 235, 0.2)",
                            "rgba(255, 206, 86, 0.2)",
                            "rgba(75, 192, 192, 0.2)",
                            "rgba(153, 102, 255, 0.2)",
                            "rgba(255, 159, 64, 0.2)",
                        ],
                        borderColor: [
                            "rgba(255, 99, 132, 1)",
                            "rgba(54, 162, 235, 1)",
                            "rgba(255, 206, 86, 1)",
                            "rgba(75, 192, 192, 1)",
                            "rgba(153, 102, 255, 1)",
                            "rgba(255, 159, 64, 1)",
                        ],
                        borderWidth: 1,
                    }
                )
            }
            // console.log(data_push)
            setDates(data_push)

        } catch (e) {
            console.log(e)
        }
    };

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const initialValues = {
        whichplatform: 'spotify',
        date:  yyyy + '-' + mm + '-' + dd,
        date_max:  yyyy + '-' + mm + '-' + dd,
    };


    return (
        <Box m="10px">
            <Header title="STATS DATA BY LINKS" subtitle="CLICK SUBMIT TO EXTRACT ALL OF YOURS STATS" />
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                            }}
                        >
                            <Typography variant="h5" color={colors.greenAccent[500]} sx={{gridColumn: "span 4"}}>
                                SET DATE START FROM TO END DATE TO GET STATS..
                            </Typography>
                            <TextField
                                select
                                label="which_platform"
                                onBlur={handleBlur}
                                value={values.whichplatform}
                                onChange={handleChange}
                                name="whichplatform"
                                error={!!touched.whichplatform && !!errors.whichplatform}
                                helperText={touched.whichplatform && errors.whichplatform}
                                sx={{ gridColumn: "span 1" }}
                                SelectProps={{
                                    native: true,
                                }}
                            >
                                {platform.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </TextField>
                            <Typography variant="h6" color={colors.greenAccent[500]} sx={{gridColumn: "span 3"}}>
                                KEEP RANGE OF 7 DAYS ONLY IT WON'T SHOW MORE THEN 7 DAYS STATS FROM START DATE.
                            </Typography>

                            <TextField
                                type="date"
                                label="Start Date"
                                onBlur={handleBlur}
                                value={values.date}
                                // onChange={(event) => {
                                //     setFieldValue('date', event.currentTarget.value)
                                // }}
                                onChange={handleChange}
                                name="date"
                                error={!!touched.date && !!errors.date}
                                helperText={touched.date && errors.date}
                                sx={{gridColumn: "span 1"}}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{ shrink: true, required: true }}
                            />

                            <TextField
                                type="date"
                                label="End Date"
                                onBlur={handleBlur}
                                value={values.date_max}

                                // onChange={(event) => {
                                //     setFieldValue('date', event.currentTarget.value)
                                // }}
                                onChange={handleChange}
                                name="date_max"
                                error={!!touched.date_max && !!errors.date_max}
                                helperText={touched.date_max && errors.date_max}
                                sx={{gridColumn: "span 1"}}
                                SelectProps={{
                                    native: true,
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                            <Box display="flex" justifyContent="start" mt="20px">
                                <Button type="submit" color="secondary" variant="contained">
                                    Get All Stats
                                </Button>
                            </Box>

                        </Box>
                    </form>
                )}
            </Formik>
            <Line options={options} data={dates} />

        </Box>
    );
};



export default Statss;
